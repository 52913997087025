import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { IPrizeDraw } from "rifa.lib.common.ts/models"
import axios from '../../../apiClient/axios'
import { PrizeDrawsApiClient } from "../../../apiClient/prizeDraws"
import { DefaultPageComponent } from "../../components/defaultPage"
import { PrizeDrawComponent } from "../../components/prizeDraw"

export const AdminPrizeDrawPage = () => {
  const { id } = useParams()
  const [data, setData] = useState<IPrizeDraw>()
  const navigator = useNavigate()

  const getData = async () => {
    let response = await new PrizeDrawsApiClient(axios).get({
      where: {
        _id: id
      }
    })

    if (response.success && response.data?.payload?.length) {
      setData(response.data?.payload![0])
    }
  }

  useEffect(() => {
    if (id && id != 'new')
      getData()
  }, [])

  return <DefaultPageComponent>
    <PrizeDrawComponent
      onConfirm={() => undefined}
      onCancel={() => navigator(-1)}
      onDelete={() => undefined}
      data={data ?? {} as IPrizeDraw} />
  </DefaultPageComponent>
}