import { TextField } from "@mui/material"
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { FormItemStyled } from "fwork.react.apptemplate"
import moment from "moment"
import React from "react"
import { IPrizeDraw } from "rifa.lib.common.ts/models"
import { DateTimePickerExtComponent } from "../../../common/components/datePicker"
import { DEFAULTGAP } from "../../globals"

export const PrizeDrawMainInputs = (props: {
  data: IPrizeDraw,
  onChange?: (data: IPrizeDraw) => void
}) => {

  return (
    <>
      <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}
        style={{ width: '100%' }}>
        <TextField label="Titulo"
          value={props.data?.title?.toString() ?? ''}
          onChange={(e) => {
            if (props.onChange) {
              props.onChange({
                ...props.data,
                title: e.target.value
              })
            }
          }}
        />
      </FormItemStyled>

      <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}
        style={{ width: '100%', maxHeight: 500 }}>
        <TextField
          multiline
          maxRows={10}
          label='Descrição'
          value={props.data?.description?.toString() ?? ''}
          onChange={(e) => {
            if (props.onChange) {
              props.onChange({
                ...props.data,
                description: e.target.value
              })
            }
          }}
        />
      </FormItemStyled>

      <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}
        style={{ width: '100%' }}>
        <TextField label='Url amigável'
          value={props.data?.friendlyUrl?.toString() ?? ''}
          onChange={(e) => {
            if (props.onChange) {
              props.onChange({
                ...props.data,
                friendlyUrl: e.target.value
              })
            }
          }}
        />
      </FormItemStyled>

      <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}
        style={{ width: '100%', maxHeight: 500 }}>
        <TextField
          multiline
          maxRows={10}
          label='Regulamento'
          value={props.data?.rules?.toString() ?? ''}
          onChange={(e) => {
            if (props.onChange) {
              props.onChange({
                ...props.data,
                rules: e.target.value
              })
            }
          }}
        />
      </FormItemStyled>

      <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}>
        <TextField
          label='Preço'
          type='number'
          value={props.data?.price?.toString() ?? ''}
          onChange={(e) => {
            if (props.onChange) {
              props.onChange({
                ...props.data,
                price: Number(e.target.value)
              })
            }
          }}
        />
      </FormItemStyled>

      <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DateTimePickerExtComponent
            // inputFormat="DD/MM/YYYY HH:mm"
            label="Data do sorteio"
            // renderInput={(params) => <TextField {...params} />}
            value={moment(props.data?.limitDateTime) || null}
            onChange={(value: any) => {
              if (props.onChange) {
                props.onChange({
                  ...props.data,
                  limitDateTime: value ? value!.toISOString() : null
                })
              }
            }}
          />
        </LocalizationProvider>
      </FormItemStyled>

      <div style={{ width: '100%' }} />

      <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}>
        <TextField
          label='Quantidade de cotas para fechar o sorteio'
          type='number'
          value={props.data?.limitCount?.toString() ?? ''}
          onChange={(e) => {
            if (props.onChange) {
              props.onChange({
                ...props.data,
                limitCount: Number(e.target.value)
              })
            }
          }}
        />
      </FormItemStyled>

      <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}>
        <TextField
          label='Quantidade mínima de cotas por bilhete'
          type='number'
          value={props.data?.minCount?.toString() ?? ''}
          onChange={(e) => {
            if (props.onChange) {
              props.onChange({
                ...props.data,
                minCount: Number(e.target.value)
              })
            }
          }}
        />
      </FormItemStyled>

      <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}>
        <TextField
          label='Quantidade máxima de cotas por bilhete'
          type='number'
          value={props.data?.maxCount?.toString() ?? ''}
          onChange={(e) => {
            if (props.onChange) {
              props.onChange({
                ...props.data,
                maxCount: Number(e.target.value)
              })
            }
          }}
        />
      </FormItemStyled>
    </>
  )
}