import { ITheme } from "rifa.lib.common.ts/models"

export const VERSION = 'SL7 Rifa 2.0 - Build 2024.12.09'

export const onLoginPayloadOverride = (login: string, pwd: string) => ({
  'login': login,
  'pwd': pwd
})

export const RoutesNames = {
  home: '/',
  friendlyUrl: '/:id',
  friendlyUrlAffiliate: '/:id/:affiliate',
  prizeDraw: '/prizedraw/:id',
  prizeDrawAffiliate: '/prizedraw/:id/:affiliate',
  ticket: '/ticket/:id',
  userRegister: '/user-register',
  userProfile: '/user-profile',
  userForgotPwd: '/user-forgotpwd',
  userChangePwd: '/user-changepwd',
  userHistory: '/user-history',
  userTickets: '/user-tickets',
  useThermes: '/use-therms',
  privacyPolicy: '/privacy-policy',

  login: '/login',
  adminDashboard: '/admin-dashboard',
  adminSettings: '/admin',
  adminUsers: '/admin-users',
  adminUser: '/admin-users/:id',
  adminPrizedraws: '/admin-prizedraws',
  adminPrizedraw: '/admin-prizedraws/:id',
  adminPrizedrawsMovement: '/admin-prizedraws-movement'
}

export const downloadPrinterApp = () => {
  const downloadLink = document.createElement('a')
  downloadLink.href = '/sl7printermanager.apk'
  document.body.appendChild(downloadLink)
  downloadLink.click()
  document.body.removeChild(downloadLink)
}

export const defaultTheme: ITheme = {
  primaryColor: "#619668",
  primaryTextColor: '#FFFFFF',
  footerColor: '#4A4A4A',
  footerTextColor: '#A3A3A3'
}