import { BsCardList } from 'react-icons/bs'
import { MenuItem } from "@mui/material";
import { IUser, UserTypes } from "rifa.lib.common.ts/models";
import React, { useState } from "react";
import { FaCloudDownloadAlt, FaCog } from "react-icons/fa";
import { RoutesNames, downloadPrinterApp } from "../../globals";
import { useNavigate } from "react-router-dom";
import { IAuthenticationState } from "fwork.react.apptemplate";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { isMobile } from 'react-device-detect';

export const AppBarUserMenuItemsComponent = () => {
  const navigate = useNavigate()
  const authenticationState: IAuthenticationState<IUser> = useSelector((state: RootState) => state.authenticationState)

  return <>
    {/* CONFIGURACOES */}
    {authenticationState.payload?.user.userTypes?.includes(UserTypes.admin) && <MenuItem
      style={{ display: 'flex', gap: 10 }}
      onClick={() => navigate(RoutesNames.adminSettings)}
    ><FaCog color="#5A5A5A" /> Configurações
    </MenuItem>}

    {/* MOVIMENTACAO */}
    {authenticationState.payload && !authenticationState.payload?.user.userTypes?.includes(UserTypes.admin) && <MenuItem
      style={{ display: 'flex', gap: 10 }}
      onClick={() => navigate(RoutesNames.adminPrizedrawsMovement)}
    ><BsCardList color="#5A5A5A" /> Movimentação
    </MenuItem>}

    {/* APP IMPRESSAO */}
    {isMobile && authenticationState.payload && <MenuItem
      sx={{ display: { xs: 'flex', md: 'none' }, gap: '10px !important' }}
      onClick={downloadPrinterApp}
    ><FaCloudDownloadAlt color="#5A5A5A" /> App Impressão
    </MenuItem>}
  </>
}