import { AppBar, Toolbar, styled } from '@mui/material';
import React, { CSSProperties, ReactNode, useState } from 'react';
import { AppBarComponent, IAppBarOptions } from '../appBar';

import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Measure from 'react-measure';

const leftAnchorWidth = 18
const rightAnchorWidth = 22

export enum AnchorDirection {
  left = 'left',
  right = 'right'
}

export interface IDefaultPageLeftAnchorOptions {
  content?: ReactNode,
  style?: CSSProperties,
}
export interface IDefaultPageMainAnchorOptions {
  content?: ReactNode,
  style?: CSSProperties,
}
export interface IDefaultPageRightAnchorOptions {
  content?: ReactNode,
  style?: CSSProperties,
}
export interface IDefaultPageLeftDrawerOptions {
  content?: ReactNode,
  showLogo?: boolean
}
export interface IDefaultPageRightDrawerOptions {
  content?: ReactNode,
}

export const ScrollBoxComponent = (props: {
  style?: CSSProperties,
  children?: React.ReactNode,
}) => {
  return <div className='scrollBoxComponent' style={{ overflow: 'auto', height: '100%', ...props.style }}>
    {props.children}
  </div>
}

const ContentWrapperStyled = styled('div')<{
  appBarHeight: string | number | undefined
}>(({ theme, appBarHeight }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'stretch',
  overflow: 'hidden',

  height: `calc(100% - ${appBarHeight}px)`,
  boxSizing: 'border-box',
}))

const LeftAnchorWrapperStyled = styled('div')(({ theme }) => ({
  visibility: 'hidden',
  width: '0 !important',
  transition: 'width .5s',
  [theme.breakpoints.up('md')]: {
    visibility: 'visible',
    width: `${leftAnchorWidth}% !important`,
    transition: 'width .5s',
  }
}))

const RightAnchorWrapperStyled = styled('div')(({ theme }) => ({
  visibility: 'hidden',
  width: '0 !important',
  transition: 'width .5s',
  [theme.breakpoints.up('lg')]: {
    visibility: 'visible',
    width: `${rightAnchorWidth}% !important`,
    transition: 'width .5s',
  }
}))

const MainAnchorWrapperStyled = styled('div')<{
  leftAnchorOptions?: IDefaultPageLeftAnchorOptions,
  rightAnchorOptions?: IDefaultPageRightAnchorOptions,
}>(({ theme, leftAnchorOptions, rightAnchorOptions }) => {
  let w = 100 - (leftAnchorOptions?.content ? leftAnchorWidth : 0) - (rightAnchorOptions?.content ? rightAnchorWidth : 0)

  return {
    // if not present and has a tab child, 
    overflow: 'auto',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: `${w}%`
    }
  }
})

let defaultPageComponent_appBarHeight = 0

export const DefaultPageComponent = (props: {
  style?: CSSProperties,
  appBarOptions?: IAppBarOptions,

  leftAnchorOptions?: IDefaultPageLeftAnchorOptions,
  mainAnchorOptions?: IDefaultPageMainAnchorOptions,
  rightAnchorOptions?: IDefaultPageRightAnchorOptions,

  leftDrawerOptions?: IDefaultPageLeftDrawerOptions,
  rightDrawerOptions?: IDefaultPageRightDrawerOptions,
}) => {

  const [drawerDirection, setDrawerDirection] = useState({ left: false, right: false });

  // const mainAnchor = () => {
  //   return <MainAnchorWrapperStyled className='mainAnchorWrapper' style={{
  //     ...props.mainAnchorOptions?.style,
  //   }}>
  //     <ScrollBoxComponent>
  //       {props.mainAnchorOptions?.content}
  //     </ScrollBoxComponent>
  //   </MainAnchorWrapperStyled>
  // }
  const mainAnchor = () => {
    return <MainAnchorWrapperStyled className='mainAnchorWrapper' style={{
      ...props.mainAnchorOptions?.style,
    }}>
      {props.mainAnchorOptions?.content}
    </MainAnchorWrapperStyled>
  }

  const leftAnchor = () => {
    return <LeftAnchorWrapperStyled className="leftAnchorWrapper" style={{
      ...props.leftAnchorOptions?.style,
    }}>
      <ScrollBoxComponent>
        {props.leftAnchorOptions?.content}
      </ScrollBoxComponent>
    </LeftAnchorWrapperStyled>
  }

  const rightAnchor = () => {
    return <RightAnchorWrapperStyled className='rightAnchorWrapper' style={{
      ...props.rightAnchorOptions?.style,
    }}>
      <ScrollBoxComponent>
        {props.rightAnchorOptions?.content}
      </ScrollBoxComponent>
    </RightAnchorWrapperStyled>
  }

  const toggleDrawerAnchorDirection = (anchor: any, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setDrawerDirection({ ...drawerDirection, [anchor]: open });
    };

  const leftDrawer = () => {
    return <React.Fragment key={AnchorDirection.left}>
      <SwipeableDrawer
        transitionDuration={{ enter: 500, exit: 500, appear: 500 }}
        anchor={AnchorDirection.left}
        open={drawerDirection[AnchorDirection.left]}
        onClose={toggleDrawerAnchorDirection(AnchorDirection.left, false)}
        onOpen={toggleDrawerAnchorDirection(AnchorDirection.left, true)}
      >
        {props.leftDrawerOptions?.content}
      </SwipeableDrawer>
    </React.Fragment>
  }

  const rightDrawer = () => {
    return <React.Fragment key={AnchorDirection.right}>
      <SwipeableDrawer
        transitionDuration={{ enter: 500, exit: 500 }}
        anchor={AnchorDirection.right}
        open={drawerDirection[AnchorDirection.right]}
        onClose={toggleDrawerAnchorDirection(AnchorDirection.right, false)}
        onOpen={toggleDrawerAnchorDirection(AnchorDirection.right, true)}
      >
        {props.rightDrawerOptions?.content}
      </SwipeableDrawer>
    </React.Fragment>
  }

  return <div aria-label='DefaultPageComponent' className='DefaultPageComponent' style={{
    height: '100%',
    ...props.style,
  }}>
    <Measure client>
      {({ measureRef, contentRect }) => {
        // a variavel tem que ser externa pra manter o ultimo valor antes do componente ser reconstruido
        // do contrario gera uma piscada na tela
        defaultPageComponent_appBarHeight = contentRect?.client?.height || defaultPageComponent_appBarHeight

        return <>
          <AppBarComponent
            {...props.appBarOptions}

            ref={measureRef}

            leftContentOptions={{
              ...props.appBarOptions?.leftContentOptions,
              click: props.appBarOptions?.leftContentOptions?.click || toggleDrawerAnchorDirection(AnchorDirection.left, true) || (props.leftDrawerOptions?.content ? (() => {
              }) : undefined),
            }}

            rightContentOptions={{
              ...props.appBarOptions?.rightContentOptions,
              click: props.appBarOptions?.rightContentOptions?.click || toggleDrawerAnchorDirection(AnchorDirection.right, true) || (props.rightDrawerOptions?.content ? (() => {
              }) : undefined),
            }}
          />

          <ContentWrapperStyled appBarHeight={defaultPageComponent_appBarHeight} className='content' style={{
            marginTop: defaultPageComponent_appBarHeight
          }}>
            {props.leftDrawerOptions?.content && leftDrawer()}
            {props.leftAnchorOptions?.content && leftAnchor()}
            {mainAnchor()}
            {props.rightAnchorOptions?.content && rightAnchor()}
            {props.rightDrawerOptions?.content && rightDrawer()}
          </ContentWrapperStyled>
        </>;
      }}
    </Measure>
  </div>
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const New_ToolBarComponent = () => {
  return <Toolbar></Toolbar>
}

export const New_AppBarComponent = () => {
  return <>
    <AppBar className='DefaultPageAppBar'>
      <New_ToolBarComponent></New_ToolBarComponent>
    </AppBar>
    <New_ToolBarComponent></New_ToolBarComponent>
  </>
}

export interface New_IDefaultPageProps {
  style?: CSSProperties,
  appBarOptions?: IAppBarOptions,

  leftAnchorOptions?: IDefaultPageLeftAnchorOptions,
  mainAnchorOptions?: IDefaultPageMainAnchorOptions,
  rightAnchorOptions?: IDefaultPageRightAnchorOptions,

  leftDrawerOptions?: IDefaultPageLeftDrawerOptions,
  rightDrawerOptions?: IDefaultPageRightDrawerOptions,
}

export const DefaultPageComponent2 = (props: New_IDefaultPageProps) => {
  return <div className='DefaultPageComponent'
    style={{
      background: 'red',
      display: 'flex',
      flexDirection: 'column',

      //
      height: '100%'
    }}>
    <New_AppBarComponent></New_AppBarComponent>
    <div className='DefaultPageContent'
      style={{
        background: 'blue',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'row',
        // gap: 20,
        // padding: 20
      }}
    >
      <div className='DefaultPageContentRight' style={{
        background: 'red'
      }}>left</div>
      <div className='DefaultPageContentCenter' style={{
        background: 'green',
        flexGrow: 1
      }}>center</div>
      <div className='DefaultPageContentLeft' style={{
        background: 'grey'
      }}>right</div>
    </div>
  </div>
}