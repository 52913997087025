import { DateTimePicker, DateTimePickerProps, PickerValidDate } from "@mui/x-date-pickers";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import React from "react";
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

export interface DateTimePickerExtComponentProps<TDate extends
  PickerValidDate, TEnableAccessibleFieldDOMStructure extends boolean = false> extends DateTimePickerProps<TDate, TEnableAccessibleFieldDOMStructure> {
}

export const DateTimePickerExtComponent = (props: DateTimePickerExtComponentProps<any, any>) => {
  return <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="pt">
    <DateTimePicker
      {...props}
      viewRenderers={props.viewRenderers ?? {
        hours: renderTimeViewClock,
        minutes: renderTimeViewClock,
        seconds: renderTimeViewClock,
      }}
    />
  </LocalizationProvider>
}