import * as locales from '@mui/material/locale';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import styled from '@mui/material/styles/styled';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { ICustomer } from 'rifa.lib.common.ts/models';
import { ApiClientUtils } from 'fwork-jsts-common/src/apiClient';
import { ImageComponent } from 'fwork.react.apptemplate';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from '../../../apiClient/axios';
import { IDotEnvProductionState } from '../../../redux/reducers/dotenvProduction.slice';
import { RootState } from '../../../redux/store';
import { DEFAULTGAP } from '../../globals';
import { HeaderComponent } from '../miscelaneous/header';

type SupportedLocales = keyof typeof locales

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: 6,
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

export const PrizeDrawRankingComponent = (props: {
  prizeDrawId?: any,
  onCloseClick?: () => void
}) => {
  const { enqueueSnackbar } = useSnackbar()

  const dotEnvProductionState: IDotEnvProductionState = useSelector((state: RootState) => state.dotEnvProductionState)

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [ranking, setRanking] = useState<ICustomer[] | undefined>()

  useEffect(() => {
    loadCustomerRanking()
  }, [])

  const loadCustomerRanking = async () => {
    try {
      const response = await axios.get(`${dotEnvProductionState.payload?.APIURL}/prizedraws/${props.prizeDrawId}/customer-ranking-by-quotecount`)
      if (response.status == 200) {
        setRanking(response.data.payload)
      }
    }
    catch (error) {
      enqueueSnackbar(ApiClientUtils.getErrorMessage(error), { variant: 'error' })
    }
  }

  //TablePagination
  const [locale] = useState<SupportedLocales>('ptBR')
  const theme = useTheme()
  const themeWithLocale = useMemo(
    () => createTheme(theme, locales[locale]),
    [locale, theme],
  )

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  return <div style={{
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    height: '100%',
    paddingBottom: 11
  }}>
    <HeaderComponent
      props={{
        style: {
          paddingInline: DEFAULTGAP
        }
      }}
      title='Ranking de cotas por cliente'
      onRefreshClick={loadCustomerRanking}
      onCancelClick={props.onCloseClick}
    />

    <div className='table-and-pagination-wrapper' style={{ padding: DEFAULTGAP, maxWidth: 'calc(100% - 10px)' }}>
      <div className='table-wrapper' style={{ boxShadow: 'rgb(0 0 0 / 16%) 1px 1px 3px', width: '100%', overflowX: 'auto', borderRadius: 5, overflow: 'auto' }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell key={1} align={'left'} style={{ minWidth: 100, fontWeight: 'bold', }}>Cliente</StyledTableCell>
              <StyledTableCell key={2} align={'left'} style={{ minWidth: 100, fontWeight: 'bold', }}>Nome</StyledTableCell>
              <StyledTableCell key={3} align={'left'} style={{ minWidth: 100, fontWeight: 'bold', }}>Telefone</StyledTableCell>
              <StyledTableCell key={4} align={'left'} style={{ minWidth: 100, fontWeight: 'bold', }}>Cpf</StyledTableCell>
              <StyledTableCell key={5} align={'left'} style={{ minWidth: 100, fontWeight: 'bold', }}>Email</StyledTableCell>
              <StyledTableCell key={6} align={'left'} style={{ minWidth: 100, fontWeight: 'bold', }}>Cotas</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {ranking?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((customer: ICustomer) => {

                return (
                  <StyledTableRow hover role="checkbox" tabIndex={-1} key={customer._id}>
                    <StyledTableCell key={1} align={'left'}>
                      <ImageComponent
                        src={`${dotEnvProductionState.payload?.APIURL}/img/${customer.imgProfileUrl?.toString()}`}
                        style={{ objectFit: 'cover', height: 48, width: 48, display: 'flex', margin: 0, padding: 0, borderRadius: '100%', }}
                      />
                    </StyledTableCell>
                    <StyledTableCell key={2} align={'left'}>{customer.name}</StyledTableCell>
                    <StyledTableCell key={3} align={'left'}>{customer.phoneNumber}</StyledTableCell>
                    <StyledTableCell key={4} align={'left'}>{customer.cpf}</StyledTableCell>
                    <StyledTableCell key={5} align={'left'}>{customer.email}</StyledTableCell>
                    <StyledTableCell key={6} align={'left'}>{customer.ticketsNumbersCount}</StyledTableCell>
                  </StyledTableRow>
                );
              })}
          </TableBody>
        </Table>
      </div>

      {ranking?.length ? <ThemeProvider theme={themeWithLocale}>
        <TablePagination style={{
          minHeight: 60,
          overflow: 'hidden'
        }}
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={ranking?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </ThemeProvider> : <></>}
    </div>
  </div>
}