import React from "react"
import { ITicketPayment } from "rifa.lib.common.ts/models"
import { DefaultCardComponent } from "../../../common/components/defaultCard"
import { TicketPaymentTransactionComponent } from "./paymentTransaction"

export const TicketPayments = (props: {
    payments: ITicketPayment[],
    onResetTimer?: () => void,
    onQrExpiration?: () => void
}) => {
    return <>
        {props.payments.map(p => <DefaultCardComponent style={{
            padding: 20,
            width: '100%',
            zIndex: 1
        }}>
            <TicketPaymentTransactionComponent transaction={p} onResetTimer={props.onResetTimer} onQrExpiration={props.onQrExpiration} />
        </DefaultCardComponent>)}
    </>
}