import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { IApiClientResult } from 'fwork-jsts-common/src/apiClient'
import { ApiResponseGetListData } from 'fwork-jsts-common/src/api'
import { IDefaultAction, IDefaultState } from 'fwork.react.apptemplate'
import { FilterQuery } from 'mongoose'
import { IPrizeDraw } from 'rifa.lib.common.ts/models'
import axios from '../../apiClient/axios'
import { PrizeDrawsApiClient } from '../../apiClient/prizeDraws'

export interface IPrizeDrawsAction extends IDefaultAction {
  payload?: IPrizeDraw[],
}

export interface IPrizeDrawsState extends IDefaultState {
  payload?: IPrizeDraw[],
}

const initState: IPrizeDrawsState = {}

export const prizeDrawsStateLoadFromApi = createAsyncThunk(
  'prizeDrawsStateLoadFromApi',
  async (arg: {
    apiUrl?: string | undefined,
    where?: FilterQuery<IPrizeDraw>,
    exclude?: any,
    onSuccess?: () => void,
    onError?: (msg?: string) => void
  }) => {
    const IapiResult: IApiClientResult<ApiResponseGetListData<IPrizeDraw>> = await new PrizeDrawsApiClient(axios, arg.apiUrl).get({
      where: arg.where,
      exclude: arg.exclude,
    })
    
    if (IapiResult.success) {
      if (arg.onSuccess)
        arg.onSuccess()
    }
    else if (arg.onError)
      arg.onError(IapiResult.msg)
    return IapiResult
  }
)

export const prizeDrawsSlice = createSlice({
  name: 'prizeDrawsSlice',
  initialState: initState,
  reducers: {
    
  },
  extraReducers: (builder) => {
    builder.addCase(prizeDrawsStateLoadFromApi.pending, (state) => {
      if (!state.options)
        state.options = {}
      state.options.loading = true
    })
    builder.addCase(prizeDrawsStateLoadFromApi.fulfilled, (state, response) => {
      if (state.options)
        state.options.loading = false
      state.payload = response.payload.data?.payload
    })
    builder.addCase(prizeDrawsStateLoadFromApi.rejected, (state) => {
      if (state.options)
        state.options.loading = false
    })
  }
})

export default prizeDrawsSlice.reducer