import axios, { AxiosResponse } from 'axios'
import { ApiClientUtils, IApiClientResult  } from 'fwork-jsts-common/src/apiClient'
import { Authentication, Style } from '../models'

export class CommonApi {
  static getAuthentication = async <UserType>(apiUrl: string, payload: any): Promise<IApiClientResult<Authentication<UserType>>> => {
    const result: IApiClientResult<Authentication<UserType>> = {}

    try {
      const response = await axios.post(apiUrl, payload)

      if (response.status === 200 && response.data) {
        result.data = Authentication.fromApiMap(response.data)
        result.success = true
      }
      else {
        result.success = false
      }
    } catch (error: unknown) {
      result.success = false
      result.msg = ApiClientUtils.getErrorMessage(error)
    }

    return result
  }

  static getStyles = (apiUrl: string): Promise<AxiosResponse<Style | any>> => {
    return axios.get(apiUrl)
  }
}