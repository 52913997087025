import { Button } from "@mui/material";
import TextField from '@mui/material/TextField';
import React, { useState } from "react";
import { DialogComponent } from "../../../common/components/dialogComponent";

export const DialogInputComponent = (props: {
  open: boolean,
  title: string,
  onConfirm?: (data?: string) => void,
  onCancel?: () => void,
  onClose?: ((event: {}, reason: "backdropClick" | "escapeKeyDown") => void) | undefined,
}) => {

  const [data, setData] = useState<string | undefined>()

  return <>
    <DialogComponent
      open={props.open}
      onClose={() => props.onClose}
    >
      <span>{props.title}:</span>

      <TextField
        value={data}
        fullWidth
        variant="outlined"
        onChange={(e) => setData(e.target.value)}
      />

      <Button
        fullWidth
        variant="contained"
        onClick={() => {
          if (props.onConfirm) props.onConfirm(data)
        }}
      >
        Confirmar
      </Button>

      <Button
        fullWidth
        variant="text"
        onClick={() => {
          if (props.onCancel) props.onCancel()
        }}
      >
        Voltar
      </Button>
    </DialogComponent>


  </>
}