import { Box, styled } from "@mui/material";
import DOMPurify from "dompurify";
import { ColorUtils, NumberUtils } from "fwork-jsts-common/src";
import { ImageComponent } from "fwork.react.apptemplate";
import React, { CSSProperties, useState } from "react";
import { IPrizeDraw } from "rifa.lib.common.ts/models";
import { IDotEnvProductionState } from "../../../redux/reducers/dotenvProduction.slice";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

const PrizeDrawCardStyled = styled('div')(({ theme }) => ({
  background: 'white',
  color: '#404040',
  boxShadow: 'rgb(0 0 0 / 16%) 1px 1px 3px',
  borderRadius: 5,
  display: 'flex',
  flexDirection: 'column',
  padding: '0 15px 15px 0',
  boxSizing: 'border-box',
  [theme.breakpoints.down('md')]: {
  },
}));

export const PrizeDrawItemCard = (props: {
  style?: CSSProperties,
  prizeDraw: IPrizeDraw,
  cutDescription?: number,
  onClick?: (prizaDraw: IPrizeDraw) => void
}) => {
  const dotEnvProductionState: IDotEnvProductionState = useSelector((state: RootState) => state.dotEnvProductionState)
  const profileBkgColor = ColorUtils.randomDarkColorHexStr()
  const [expanded, setExpanded] = useState<boolean>(false)

  return <PrizeDrawCardStyled
    onClick={() => {
      if (props.onClick)
        props.onClick(props.prizeDraw)
    }}

    className="prizeDrawCard"
    style={{
      display: 'flex',
      flexDirection: 'column',
      ...props.style,
    }}>

    <div style={{
      flexGrow: 1,
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      margin: '15px 0 0 15px',
      borderRadius: 5,
      position: 'relative'
    }}>
      <div aria-label="back" style={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundImage: `url(${dotEnvProductionState.payload?.APIURL}/img/${props.prizeDraw.imgUrl?.toString()})`,
        backgroundSize: 'cover',
        borderRadius: 5,
        opacity: .25
      }}></div>
      <ImageComponent className="thumb" style={{
        objectFit: 'contain',
        height: '100%',
        zIndex: 1,
        minHeight: 270
      }} src={`${dotEnvProductionState.payload?.APIURL}/img/${props.prizeDraw.imgUrl?.toString()}`} />
      
      {props.prizeDraw.closed ? <div style={{
        position: 'absolute',
        bottom: 10,
        left: 10,
        marginRight: 10,
        background: 'red',
        color: 'white',
        borderRadius: 5,
        paddingInline: 15,
        paddingBlock: 10,
        textAlign: 'center',
        zIndex: 2,
        fontWeight: 'bold'
      }}>
        ENCERRADO
      </div> : <></>}

      {/* {!props.prizeDraw.closed ? <div style={{
        position: 'absolute',
        bottom: 10,
        left: 10,
        marginRight: 10,
        background: '#3CB371',
        color: 'white',
        borderRadius: 5,
        paddingInline: 15,
        paddingBlock: 10,
        textAlign: 'center',
        zIndex: 2
      }}>
        {`Participe por apenas: R$ ${NumberUtils.getNumberStr(props.prizeDraw.price || 0)}🔥`}
      </div> : <></>} */}
    </div>

    <b style={{
      margin: '5px 0 0 15px',
      textAlign: 'start',
    }}>{props.prizeDraw.title}</b>

    <div style={{
      margin: '5px 0 0 15px',
      textAlign: 'start',
    }}>
      {!props.cutDescription || expanded ?
        <span dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(props.prizeDraw.description?.toString() || '')
        }}></span> :
        props.prizeDraw.description?.substring(0, props.cutDescription)}

      {props.cutDescription && (props.prizeDraw.description?.length || 0) > props.cutDescription ? <b
        onClick={() => {
          if (!props.onClick)
            setExpanded(!expanded)
        }}
        style={{
          cursor: 'pointer',
          color: 'grey',
          textDecoration: 'none',
        }}
      > {expanded ? '...menos' : 'Mais...'}</b> : <></>}
    </div>
  </PrizeDrawCardStyled>
}