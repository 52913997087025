import React from "react"
import { DashboardComponent } from "../../components/dashboard"
import { DefaultPageComponent } from "../../components/defaultPage"

export const DashboardPage = () => {
  return <DefaultPageComponent>
    <div style={{
      background: '#f1f0f0',
      height: '100%',
    }}>
      <DashboardComponent />
    </div>
  </DefaultPageComponent>
}