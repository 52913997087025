import { CSSProperties } from 'react'

export const stylesModule = () => {
  const root: CSSProperties = {
    flexGrow: 1
  }

  return {
    root
  }
}