import React, { useEffect, useState } from "react"
import { IPrizeDraw, ITicket } from "rifa.lib.common.ts/models"
import axios from '../../../apiClient/axios'
import { TicketsApiClient } from "../../../apiClient/tickets"
import { TicketItemType } from "../../../common/components/ticketSmall"
import { TicketsComponent } from "../../../common/components/tickets"
import { DEFAULTGAP } from "../../globals"

export const PrizeDrawTicketsComponent = (props: {
  prizeDraw?: IPrizeDraw,
  onBackClick?: () => void
}) => {
  const [tickets, setTickets] = useState<ITicket[]>([])
  
  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    try {
      const response = await new TicketsApiClient(axios).get({
        where: {
          prizeDrawId: {
            type: 'ObjectId',
            value: props.prizeDraw?._id
          }
        },
        nested: 'customer'
      })

      if (response.success && response.data?.payload)
        setTickets(response.data.payload)
    } catch (error) {

    }
  }

  return <>
    <TicketsComponent gap={DEFAULTGAP} title="Movimentação" itemType={TicketItemType.user} tickets={tickets} onCloseClick={props.onBackClick} />
  </>
}