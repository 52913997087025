import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from 'react-router-dom'
import { ITicket } from "rifa.lib.common.ts/models"
import axios from '../../../apiClient/axios'
import { TicketsApiClient } from "../../../apiClient/tickets"
import { TicketItemType } from "../../../common/components/ticketSmall"
import { TicketsComponent } from "../../../common/components/tickets"
import { IDotEnvProductionState } from '../../../redux/reducers/dotenvProduction.slice'
import { RootState } from "../../../redux/store"

export const UserTicketsComponent = (props: {
  where: string
}) => {
  const dotEnvProductionState: IDotEnvProductionState = useSelector((state: RootState) => state.dotEnvProductionState)
  const [tickets, setTickets] = useState<ITicket[] | undefined>([])
  const navigate = useNavigate()

  useEffect(() => {
    if (dotEnvProductionState.payload)
      loadData()
  }, [props.where, dotEnvProductionState])

  const loadData = async () => {
    if (props.where)
      try {
        const response = await new TicketsApiClient(axios).get({
          where: { "$or": [{ "customer.cpf": props.where }, { "customer.phoneNumber": props.where }, { "customer.email": props.where }] },
          nested: 'customer,prizeDraw'
        })

        if (response.success && response.data)
          setTickets(response.data.payload)
      } catch (error) {

      }
  }

  return tickets?.length ? <TicketsComponent
    title='Meus Números'
    itemType={TicketItemType.prizeDraw}
    tickets={tickets} onCloseClick={() => navigate(-1)}
    onRefreshClick={loadData}
  /> : <></>
}