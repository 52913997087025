import { ITicket } from "rifa.lib.common.ts/models"
import { ApiClientUtils } from "fwork-jsts-common/src/apiClient"
import { useSnackbar } from 'notistack'
import { default as React, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import axios from "../../../apiClient/axios"
import { TicketsApiClient } from "../../../apiClient/tickets"
import dotenvProductionSlice, { IDotEnvProductionState } from "../../../redux/reducers/dotenvProduction.slice"
import { ILocalSettingsState } from "../../../redux/reducers/localSettings.slice"
import { IPrizeDrawsState } from "../../../redux/reducers/prizeDraws.slice"
import { RootState } from "../../../redux/store"
import { BannersComponent } from '../banners'
import { SizedContainerStyled } from "../defaultPage"
import { PrizeDrawsComponent } from "../prizeDraws"
import { PrizeDrawItemHighlightedComponent } from "../prizeDraws/highlighted"
import { TicketWinnersComponent } from "../ticketWinners"

export const HomeComponent = () => {
  const localSettingsState: ILocalSettingsState = useSelector((state: RootState) => state.localSettingsState)
  const prizedrawState: IPrizeDrawsState = useSelector((state: RootState) => state.prizeDrawsState)
  const prizedraws = localSettingsState.payload.showClosedPrizeDraws ? prizedrawState.payload : prizedrawState?.payload?.filter((p: any) => !p.CLOSED) || []
  const highlightedPrizedraw = prizedraws?.filter((p: any) => p.HIGHLIGHT)[0] || (prizedraws || [])[0]
  const dotEnvProductionState: IDotEnvProductionState = useSelector((state: RootState) => state.dotEnvProductionState)

  const [winners, setWinners] = useState<ITicket[] | undefined>()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (dotEnvProductionState.payload?.APIURL)
      loadWinners()
  }, [dotEnvProductionState.payload?.APIURL])

  const loadWinners = async () => {
    try {
      const response = await new TicketsApiClient(axios).getWinners()

      if (response.success && response.data) {
        setWinners(response.data.payload)
      }
    } catch (error) {
      enqueueSnackbar(ApiClientUtils.getErrorMessage(error), { variant: 'error' })
    }
  }

  return <>
    <BannersComponent />

    {highlightedPrizedraw ? <PrizeDrawItemHighlightedComponent props={{
      style: {
        flexGrow: 1,
        boxShadow: '0px -5px 15px -5px rgba(0,0,0,0.75)',
        zIndex: 1,
      }
    }} highlightedPrizedraw={highlightedPrizedraw} /> : <></>}

    {(prizedraws?.length || 0) > 1 ? <SizedContainerStyled className="SizedContainerStyled" style={{
      background: '#F1F0F0'
    }}>
      <PrizeDrawsComponent prizedraws={prizedraws || []} />
    </SizedContainerStyled> : <></>}

    {winners?.length ? <SizedContainerStyled className="SizedContainerStyled" style={{
      background: 'rgb(241, 240, 240)'
    }}>
      <TicketWinnersComponent winners={winners} />
    </SizedContainerStyled> : <></>}
  </>
}