import { ImageComponent } from 'fwork.react.apptemplate'
import React from 'react'
import Carousel from 'react-material-ui-carousel'
import { useSelector } from 'react-redux'
import { IBanner } from 'rifa.lib.common.ts/models'
import { IDotEnvProductionState } from '../../../redux/reducers/dotenvProduction.slice'
import { ISettingsState } from '../../../redux/reducers/settings.slice'
import { RootState } from '../../../redux/store'
import { styled } from '@mui/material'

const BannerDivStyled = styled('div')(({ theme }) => {
  return {
    transition: 'all .5s',
    [theme.breakpoints.up('md')]: {
      height: 350,
    },
    [theme.breakpoints.down('md')]: {
      height: 250,
    },
    [theme.breakpoints.down('sm')]: {
      height: 150,
    },
  }
})

export const BannersComponent = () => {

  const settingsState: ISettingsState = useSelector((state: RootState) => state.settingsState)
  const dotEnvProductionState: IDotEnvProductionState = useSelector((state: RootState) => state.dotEnvProductionState)

  let banners: IBanner[] = []
  if (settingsState.payload?.length) {
    if (settingsState.payload[0].banners?.length)
      banners = settingsState.payload[0].banners
  }

  return <>
    <BannerDivStyled>
      <Carousel
        autoPlay
        indicators={false}
        animation='fade'
      >
        {banners.map((item, idx) => {
          return (
            <BannerDivStyled style={{
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
            }}
              key={idx}>
              <BannerDivStyled
                style={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  left: 0,
                  bottom: 0,
                  backgroundImage: `url(${item.url?.indexOf('base64') != -1 ? item.url?.toString() : `${dotEnvProductionState.payload?.APIURL}/img/${item.url}`})`,
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  opacity: .4,
                }}></BannerDivStyled>
              <ImageComponent
                onClick={item.link ? () => {
                  if (item.link)
                    window.open(item.link.toString(), '_blank')
                }
                  : undefined}
                src={item.url?.indexOf('base64') != -1 ? item.url?.toString() : `${dotEnvProductionState.payload?.APIURL}/img/${item.url}`} style={{
                  zIndex: 1,
                  objectFit: `${item.objectFit == 1 ? 'contain' : 'cover'}`,
                  width: '100%',
                  cursor: item.link ? 'pointer' : 'default',
                }} />
            </BannerDivStyled>
          )
        })}
      </Carousel>
    </BannerDivStyled>
  </>
}