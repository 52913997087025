export class Authentication<UserType> {
  token: string
  user: UserType

  constructor(token: string, user: UserType,) {
    this.token = token
    this.user = user
  }

  static fromApiMap<UserType>(map: any) {
    return new Authentication<UserType>(
      map['token'],
      map['user'],
    )
  }
}

export class Style {
  primaryColor: string = '#282828'
  primaryTextColor: string = '#FFFFFF'
  secondaryColor: string = '#585858'
  secondaryTextColor: string = '#FFFFFF'
  backgroundColor: string = '#383838'
  backgroundTextColor: string = '#FFFFFF'

  constructor(
    primaryColor?: string,
    primaryTextColor?: string,
    secondaryColor?: string,
    secondaryTextColor?: string,
    backgroundColor?: string,
    backgroundTextColor?: string,
  ) {
    this.primaryColor = primaryColor || this.primaryColor
    this.primaryTextColor = primaryTextColor || this.primaryTextColor
    this.secondaryColor = secondaryColor || this.secondaryColor
    this.secondaryTextColor = secondaryTextColor || this.secondaryTextColor
    this.backgroundColor = backgroundColor || this.backgroundColor
    this.backgroundTextColor = backgroundTextColor || this.backgroundTextColor
  }

  static fromApiMap(from: any): Style {
    const result: Style = new Style(
      from['primaryColor'],
      from['primaryTextColor'],
      from['secondaryColor'],
      from['secondaryTextColor'],
      from['backgroundColor'],
      from['backgroundTextColor'],
    )

    return result
  }
}

export interface ProgressOptions {
  show?: boolean
}