import { styled } from "@mui/material";
import { CommonUtils } from "fwork-jsts-common/src";

const defaultGap = 10

export interface FormStyledProps {
  gap?: number,
  rowGap?: number,
  columnGap?: number,
  fullWidth?: boolean
}

export const FormStyled = styled('div')<FormStyledProps>(({ theme, gap, rowGap, columnGap, fullWidth }) => {
  const _columnGapTmp = !CommonUtils.isNullOrEmpty(columnGap) ? columnGap : (!CommonUtils.isNullOrEmpty(gap) ? gap : defaultGap)
  const _rowGapTmp = !CommonUtils.isNullOrEmpty(rowGap) ? rowGap : (!CommonUtils.isNullOrEmpty(gap) ? gap : defaultGap)

  return {
    width: fullWidth ? '100%' : undefined,
    display: 'flex',
    flexWrap: 'wrap',
    padding: `0 ${_rowGapTmp}px ${_columnGapTmp}px 0`,
    boxSizing: 'border-box',
  }
})

export const FormItemStyled = styled('div')<FormStyledProps>(({ theme, gap, rowGap, columnGap, fullWidth }) => {
  const _columnGapTmp = !CommonUtils.isNullOrEmpty(columnGap) ? columnGap : (!CommonUtils.isNullOrEmpty(gap) ? gap : defaultGap)
  const _rowGapTmp = !CommonUtils.isNullOrEmpty(rowGap) ? rowGap : (!CommonUtils.isNullOrEmpty(gap) ? gap : defaultGap)

  return {
    margin: `${_columnGapTmp}px 0 0 ${_rowGapTmp}px`,
    display: 'flex',
    flexDirection: 'column',
    width: `calc(100% - ${_rowGapTmp}px)`,
    boxSizing: 'border-box',
    [theme.breakpoints.up('md')]: {
      width: fullWidth ? `calc(100% - ${_rowGapTmp}px)` : `calc(50% - ${_rowGapTmp}px)`
    },
    [theme.breakpoints.up('lg')]: {
      width: fullWidth ? `calc(100% - ${_rowGapTmp}px)` : `calc(33.33% - ${_rowGapTmp}px)`
    }
  }
})

export const Form10x20Styled = styled(FormStyled)(({theme}) => ({
  [theme.breakpoints.up('sm')]: {
    padding: `0 20px 20px 0`,
  }
}))

export const FormItem10x20Styled = styled(FormItemStyled)(({theme, fullWidth}) => {
  const gap = 20
  return {
    [theme.breakpoints.up('sm')]: {
      margin: `${gap}px 0 0 ${gap}px`,
    },
    [theme.breakpoints.up('md')]: {
      width: fullWidth ? `calc(100% - ${gap}px)` : `calc(50% - ${gap}px)`
    },
    [theme.breakpoints.up('lg')]: {
      width: fullWidth ? `calc(100% - ${gap}px)` : `calc(33.33% - ${gap}px)`
    }
  }
})