import { IconButton, Button, ExtendButtonBase, IconButtonTypeMap } from '@mui/material'
import React, { useState } from "react"
import { BsFillImageFill } from 'react-icons/bs'
import { FaPen } from 'react-icons/fa'
import { ImageComponent } from '../image'
import CircularProgress from '@mui/material/CircularProgress';

const SelectImgComponentContent = (props: {
  imgProps: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
  emptyElement?: React.ReactNode,
}) => {
  return props.imgProps.src ? <ImageComponent {...props.imgProps} /> : props.emptyElement ? <>{props.emptyElement}</> : <BsFillImageFill style={{
    color: '#EEE',
    ...props.imgProps.style,
  }} />
}

export const SelectImgComponent = (props: {
  id: string,
  props?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  imgProps: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
  emptyElement?: React.ReactNode,
  alwaysShowButton?: boolean,
  onSelectImg?: (fileName: string) => void,
  loading?: boolean
}) => {

  function useDisplayImage() {
    const [imgSrc, setImgSrc] = useState(props.imgProps.src);

    function uploader(e: any) {
      const imageFile = e.target.files[0];

      const reader = new FileReader();
      reader.addEventListener("load", (e: any) => {
        if (props.onSelectImg)
          props.onSelectImg(e.target.result)
        return setImgSrc(e.target.result)
      });

      reader.readAsDataURL(imageFile);
    }

    return { result: imgSrc, uploader };
  }
  const { result: imgSrc, uploader } = useDisplayImage()

  const onIconClick = () => {
    const input = document.getElementById(props.id);

    if (input) {
      input.click();
    }
  };

  return <div style={{
    position: 'relative'
  }} {...props.props}>
    <input
      type="file"
      id={props.id}
      onChange={(e: any) => {
        uploader(e);
      }}
      style={{ display: 'none' }}
    />

    {props.alwaysShowButton || (
      (typeof props.imgProps.style?.width === 'number' && props.imgProps.style.width >= 50) &&
      (typeof props.imgProps.style?.height === 'number' && props.imgProps.style.height >= 50)
    ) ?
      props.loading ? <CircularProgress /> :
        <>
          <SelectImgComponentContent
            imgProps={{
              ...props.imgProps,
              // src: imgSrc
              src: props.imgProps.src
            }}
            emptyElement={props.emptyElement}
          />
          <IconButton onClick={onIconClick} style={{
            position: 'absolute',
            bottom: 0,
            left: 40,
            background: '#1a7bcf',
            cursor: 'pointer'
          }}>
            <FaPen size={20} color={'white'} />
          </IconButton>
        </> :
      <Button
        onClick={onIconClick}
        style={{
          padding: 0,
          ...props.imgProps.style
        }}>

        <SelectImgComponentContent
          imgProps={{
            ...props.imgProps,
            src: imgSrc
          }} emptyElement={props.emptyElement}
        />
      </Button>}
  </div>
}