import { ApiClientUtils } from 'fwork-jsts-common/src/apiClient'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useParams } from 'react-router-dom'
import { IPrizeDraw } from "rifa.lib.common.ts/models"
import axios from '../../../apiClient/axios'
import { PrizeDrawsApiClient } from "../../../apiClient/prizeDraws"
import { BreadcrumbComponent } from "../../../common/components/breadcrumb"
import { IDotEnvProductionState } from '../../../redux/reducers/dotenvProduction.slice'
import { RootState } from "../../../redux/store"
import { DefaultPageComponent } from "../../components/defaultPage"
import { PrizeDrawComponent } from "../../components/prizeDraw"
import mongoose, { ObjectId } from 'mongoose'
import { WebUtils } from 'fwork-jsts-common/src'

const BackgroundOverlay = (props: {
  imgUrl: String,
  props?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
}) => {
  return <div
    {...props.props}
    style={{
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      backgroundImage: `url(${props.imgUrl})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      ...props.props?.style
    }}
  ></div>
}

export const PrizeDrawPage = () => {
  const { id, affiliate } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const dotEnvProductionState: IDotEnvProductionState = useSelector((state: RootState) => state.dotEnvProductionState)
  const [prizeDraw, setPrizeDraw] = useState<IPrizeDraw>()

  useEffect(() => {
    if (affiliate)
      WebUtils.setCookie('@affiliateCode', JSON.stringify(affiliate), 1)

    if (dotEnvProductionState.payload)
      getData()

    // grava no cookie
  }, [dotEnvProductionState])

  const getData = async () => {
    try {

      let isObjectId = mongoose.Types.ObjectId.isValid(id as any)

      const response = await new PrizeDrawsApiClient(axios).get({
        where: {
          [isObjectId ? '_id' : 'friendlyUrl']: id
        },
        exclude: 'TICKETS'
      })

      if (response.success && response.data?.payload?.length)
        setPrizeDraw(response.data.payload[0])
    } catch (error) {
      enqueueSnackbar(ApiClientUtils.getErrorMessage(error), { variant: 'error' })
    }
  }

  return <>
    {prizeDraw ? <DefaultPageComponent
      hideFlexGrow={true}
      content={<>
      <BreadcrumbComponent props={{
        style: {
          maxWidth: 1024,
          marginInline: 'auto',
          boxSizing: 'border-box'
        }
      }} /><PrizeDrawComponent prizeDraw={prizeDraw} /></>}
      style={{
        background: '#F1F0F0',
      }}
    /> : <></>}
  </>
}