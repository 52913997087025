import { LoginComponent } from "fwork.react.apptemplate"
import React from "react"
import { useSelector } from "react-redux"
import { RoutesNames, onLoginPayloadOverride } from "../../../common/globals"
import { IDotEnvProductionState } from "../../../redux/reducers/dotenvProduction.slice"
import { RootState } from "../../../redux/store"
import { useNavigate } from "react-router-dom"

export const Login = () => {
  const dotEnvProductionState: IDotEnvProductionState = useSelector((state: RootState) => state.dotEnvProductionState)
  const navigate = useNavigate()

  return <LoginComponent
    apiUrl={`${dotEnvProductionState.payload?.APIURL}/auth`}
    onPayloadOverride={onLoginPayloadOverride}
    onSuccess={() => navigate(RoutesNames.home)}
  />
}