export interface IAuthentication {
  token?: string
}

export interface IAuthenticationTokenData {
  paymentProvider: IPAYMENTPROVIDER,
  exDate: string
}

export interface IPAYMENTPROVIDER {
  _id?: string | any,
  NAME?: string,
  SL7PAYAPIKEY?: string,
  PIXENTRANCEFEE?: number,
  PIXOUTFEE?: number
}

export enum PAYMENTTRANSACTIONTYPE {
  generic = 0,
  pix = 1,
}

export enum PAYMENTTRANSACTIONSTATUS {
  undefined = -1,
  waitingConfirmation = 0,
  waitingPayment = 1,
  paid = 2,
  canceled = 9,
}

export const PaymentTransactionsStatusDescriptions = [
  {
    description: 'Aguardando confirmação',
    value: 0,
  },
  {
    description: 'Aguardando pagamento',
    value: 1,
  },
  {
    description: 'Pago',
    value: 2,
  },
  {
    description: 'Cancelado',
    value: 9,
  },
]

export interface IPAYMENTTRANSACTION_WEBHOOK {
  url?: string,
  token?: string,
}

export interface IPAYMENTTRANSACTION {
  _id?: string | any,
  REFERENCE?: string,
  TYPE?: PAYMENTTRANSACTIONTYPE,
  STATUS?: PAYMENTTRANSACTIONSTATUS,
  DESCRIPTION?: string,
  CPF?: string,
  VALUE?: number,
  // ESSE DADO VEM DA SL7PAY E ESTA EM UTC
  EXPIRATIONDATE?: string, 
  PAYMENTPROVIDER?: IPAYMENTPROVIDER,
  TAG?: string,

  WEBHOOK?: IPAYMENTTRANSACTION_WEBHOOK

  SL7PAYID?: string,
  QRURL?: string,
  QRIMG?: string,
  PIXENTRANCEFEE?: number,
  PIXOUTFEE?: number,
  NOTE?: string
}