import { InputAdornment, TextFieldProps } from "@mui/material";
import TextField from '@mui/material/TextField';
import React, { useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { FaLock } from "react-icons/fa";

export const TextPwdComponent = (props: {
  props?: TextFieldProps
}) => {
  const [showPwd, setShowPwd] = useState(false)

  return <TextField
    autoComplete="old-password"
    type={`${showPwd ? 'text' : 'password'}`}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <FaLock />
        </InputAdornment>
      ),
      endAdornment: (
        <div>
          <InputAdornment position="end" style={{
            cursor: 'pointer'
          }}>
            {showPwd ? <AiFillEye size={20} onClick={(e) => {
              setShowPwd(!showPwd)
            }} /> : <AiFillEyeInvisible size={20} onClick={(e) => {
              setShowPwd(!showPwd)
            }} />}
          </InputAdornment>
        </div>
      ),
      ...props.props?.InputProps,
    }}
    {...props.props}
  />

}