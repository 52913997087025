import React from "react"
import { useSelector } from "react-redux"
import { Navigate } from "react-router-dom"
// import { Authentication } from "../../models"
import { IAuthenticationState } from "../../redux/reducers/authenticationtest.slice"
import { IRootState } from "../../redux/types"
import { ILoginOptions, LoginComponent } from "./login"

export interface RequiredAuthWrapperComponentProps<UserType> {
  children?: React.ReactNode,
  router?: string,
  // checkAuthCallBack?: (authentication?: Authentication<UserType>) => boolean | undefined
  loginOptions?: ILoginOptions<UserType>,
}

export function RequiredAuthWrapperComponent<UserType>(props: RequiredAuthWrapperComponentProps<UserType>) {
  const authenticationState: IAuthenticationState<UserType> = useSelector((state: IRootState<UserType>) => state.authenticationState)

  const AccessDeniedComponent = () => {
    return (
      props.router ?
        // custom route in case authentication fails
        <Navigate to={props.router} /> :
        // does not have custom route in case authentication fails
        (
          // props.loginOptions ?
          props.loginOptions?.apiUrl ?
            // parameters were defined to call the default login
            // <LoginComponent checkAuthCallBack={props.checkAuthCallBack} {...props.loginOptions} /> :
            <LoginComponent {...props.loginOptions} /> :
            // the parameters to call the default login were not defined
            <div style={{
              height: '100vh',
              width: '100vw',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>Acesso negado</div>
        )
    )
  }

  return <>
    {
      authenticationState.payload?.token ?
        // user has a token
        (
          // (props.checkAuthCallBack ? props.checkAuthCallBack!(authenticationState.payload) : true) ?
          (props.loginOptions?.checkAuthCallBack ? props.loginOptions.checkAuthCallBack(authenticationState.payload) : true) ?
            // custom callback returns true, should display the component
            <>{props.children}</> :
            // custom callback returns false
            <AccessDeniedComponent />
        ) :
        // user does not have a token
        <AccessDeniedComponent />
    }
  </>

}