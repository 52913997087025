import { styled } from '@mui/material'

export const TicketWinnerProfileImageDiv = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 'calc(50% - (100px/2))',
  left: 'calc(50% - (100px/2))',
  width: 100,
  height: 100,
  borderRadius: '100%',
  objectFit: 'cover',
  [theme.breakpoints.up('lg')]: {
    top: 'calc(50% - (60px/2))',
    left: 'calc(50% - (60px/2))',
    width: 60,
    height: 60,
  }
}))