import { useSnackbar } from 'notistack'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { IUser } from 'rifa.lib.common.ts/models'
import { RoutesNames } from '../../../common/globals'
import { IDotEnvProductionState } from '../../../redux/reducers/dotenvProduction.slice'
import { IUsersState, usersStateLoadFromApi } from '../../../redux/reducers/users.slice'
import { RootState } from '../../../redux/store'
import { DEFAULTGAP } from '../../globals'
import { HomeTypes } from '../../types'
import { HeaderComponent } from '../miscelaneous/header'
import { ResultSetItemComponent } from '../miscelaneous/resultSetItem'

export const UsersComponent = (props: {
  data: IUser[] | undefined,
  contentType: HomeTypes
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch<any>()
  const { enqueueSnackbar } = useSnackbar()
  const usersState: IUsersState = useSelector((state: RootState) => state.usersState)
  const dotEnvProductionState: IDotEnvProductionState = useSelector((state: RootState) => state.dotEnvProductionState)

  useEffect(() => {
    if (!props.data && !usersState.payload)
      getData()
  }, [])

  const getData = () => {
    dispatch(usersStateLoadFromApi({
      onError: (msg: string | undefined) => {
        enqueueSnackbar(msg, { variant: 'error' })
      }
    }))
  }

  return <>
    <HeaderComponent
      props={{
        style: {
          paddingInline: DEFAULTGAP
        }
      }}
      title='Usuários'
      onAddClick={() => {
        navigate(RoutesNames.adminUser.replace(':id', 'new'))
      }}
      onRefreshClick={getData}
    />

    {usersState.payload?.map(user => <ResultSetItemComponent
      key={user._id}
      showImg={true}
      imgProps={{
        src: `${dotEnvProductionState.payload?.APIURL}/img/${user.imgProfileUrl?.toString()}`
      }}
      label={`${user.login}, ${user.name}`}
      obj={user}
      style={user?.style}
      onClick={(user) => {
        navigate(RoutesNames.adminUser.replace(':id', user._id))
      }} />)}
  </>
}