import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import moment from 'moment';
import * as React from 'react';
import { useState } from 'react';
import { HtmlRawViewComponent } from '../htmlRawView';


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function tabProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const RulesPrizeDrawComponent = (props: {
  detals?: string | number,
  dateTime?: string,
  rules?: string,
}) => {
  const [currTab, setCurrTab] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrTab(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={currTab} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Detalhes" {...tabProps(0)} />
          <Tab label="Regulamento" {...tabProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={currTab} index={0}>
        <div style={{ padding: 15 }}>
          <div>{`Por apenas: R$ ${props.detals} 🔥`}</div>
          {props.dateTime ? <div>{`Sorteio: ${moment(props.dateTime as string).format('DD/MM/YYYY HH:mm')}`}</div> : <></>}
        </div>
      </TabPanel>
      <TabPanel value={currTab} index={1}>
        <div style={{ padding: 15 }}>
          {props.rules ? <HtmlRawViewComponent props={{
            style: {
              padding: 0
            }
          }} content={props.rules} /> : <></>}
        </div>
      </TabPanel>
    </Box>
  );
}