import React from "react"
import { useSelector } from "react-redux"
import { IUsersState } from "../../../redux/reducers/users.slice"
import { RootState } from "../../../redux/store"
import { DefaultPageComponent } from "../../components/defaultPage"
import { UsersComponent } from "../../components/users"
import { HomeTypes } from "../../types"

export const UsersPage = () => {
  const usersState: IUsersState = useSelector((state: RootState) => state.usersState)

  return <DefaultPageComponent>
    <UsersComponent data={usersState.payload} contentType={HomeTypes.users} />
  </DefaultPageComponent>
}