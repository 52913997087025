import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { DialogComponent } from '../dialogComponent'
import { CustomerDataSmallComponent } from '../../../site/components/customerData/small'
import { RoutesNames } from '../../globals'

export const AppBarMyTicketOptionComponent = (props: {
  textColor?: string
}) => {
  const [openDialog, setOpenDialog] = useState(false)
  const navigate = useNavigate()

  return <>
    <DialogComponent
      open={openDialog}
      onClose={() => setOpenDialog(false)}
    >
      <CustomerDataSmallComponent
        onConfirm={(data?: string) => {
          navigate(RoutesNames.userTickets, {
            state: {
              filter: data
            }
          })
        }}
        onCancel={() => setOpenDialog(false)}
      />
    </DialogComponent>

    <Link
      onClick={() => setOpenDialog(true)}
      style={{
        color: props.textColor,
        textDecoration: 'none',
        whiteSpace: 'nowrap',
      }} to=''>Meus Números</Link>
  </>
}