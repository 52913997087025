import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { CommonApi } from '../../api/common'
import { Style } from '../../models'
import { IDefaultAction, IDefaultState } from '../types'

export interface IStylesAction extends IDefaultAction {
  payload: Style
}

export interface IStylesState extends IDefaultState {
  payload: Style
}

const initState: IStylesState = {
  payload: new Style(),
}

export const stylesStateLoadFromApi = createAsyncThunk(
  'stylesStateLoadFromApi',
  async (arg: {
    apiUrl: string
  }) => {
    const styles = Style.fromApiMap((await CommonApi.getStyles(arg.apiUrl)).data)
    return styles
  }
)

export const stylesSlice = createSlice({
  name: 'styles',
  initialState: initState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder.addCase(stylesStateLoadFromApi.pending, (state) => {
      if (state.options)
        state.options.loading = true
    })
    builder.addCase(stylesStateLoadFromApi.fulfilled, (state, response) => {
      if (state.options)
        state.options.loading = false
      if (response.payload)
        state.payload = response.payload
    })
    builder.addCase(stylesStateLoadFromApi.rejected, (state) => {
      if (state.options)
        state.options.loading = false
    })
  }
})

export default stylesSlice.reducer