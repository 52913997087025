import MoreIcon from '@mui/icons-material/MoreVert';
import { Box, IconButton, Menu, TextField } from "@mui/material";
import { IUser } from "rifa.lib.common.ts/models";
import { IAppBarOptions, IAuthenticationState, ImageComponent } from "fwork.react.apptemplate";
import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";
import { useSelector } from "react-redux";
import { prizeDrawsStateLoadFromApi } from "../../../redux/reducers/prizeDraws.slice";
import { ISettingsState } from "../../../redux/reducers/settings.slice";
import { RootState, store } from "../../../redux/store";
import { AppBarMyTicketsMenuItemComponent } from "../../../common/components/appBarOptions/appBarMyTicketsMenuItem";
import { AppBarMyTicketOptionComponent } from "../../../common/components/appBarOptions/appBarMyTicketsOption";
import { AppBarUserMenuItemsComponent } from "../../../common/components/appBarOptions/appBarUserMenuItems";
import { AppBarUserOptionsComponent } from "../../../common/components/appBarOptions/appBarUserOptions";
import { defaultTheme, RoutesNames } from '../../../common/globals';
import { useNavigate } from 'react-router-dom';

export interface ICustomAppBarOptions {
  showSearch?: boolean,
}

export const appBarOptions = (props?: ICustomAppBarOptions): IAppBarOptions => {
  const settingsState: ISettingsState = useSelector((state: RootState) => state.settingsState)
  const [searchText, setSearchText] = useState('')
  const [menuAnchor, setAnchorElemnt] = useState<Element | (() => Element) | null | undefined>(null)
  const openMenu = Boolean(menuAnchor)
  const authenticationState: IAuthenticationState<IUser> = useSelector((state: RootState) => state.authenticationState)
  const navigate = useNavigate()

  const search = () => {
    store.dispatch<any>(prizeDrawsStateLoadFromApi({
      where: {
        $or: [{
          title: { $regex: searchText, $options: 'i' }
        }, {
          description: { $regex: searchText, $options: 'i' }
        }]
      },
      exclude: 'TICKETS'
    }))
  }

  return {
    show: true,

    mainContent: <div style={{
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      gap: 10
    }}>
      <Menu
        id="basic-menu"
        anchorEl={menuAnchor}
        open={openMenu}
        onClose={() => setAnchorElemnt(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>

        <AppBarUserMenuItemsComponent />
        <AppBarMyTicketsMenuItemComponent />

      </Menu>

      {/* LEFT CONTENT */}
      <span style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: 10,
      }}>
        {settingsState.payload?.length ? <ImageComponent onClick={() => navigate(RoutesNames.home)} style={{
          maxHeight: 50,
          marginRight: 10,
          verticalAlign: 'middle',
          cursor: 'pointer'
        }} src={settingsState.payload[0].companyLogoUrl?.toString()} /> : <></>}

        <Box sx={{ display: { xs: 'none', md: 'initial' } }}>
          <AppBarMyTicketOptionComponent textColor={settingsState?.payload?.[0]?.theme?.primaryTextColor ?? defaultTheme.primaryTextColor} />
        </Box>
      </span>

      {/* SEARCH */}
      {props?.showSearch && <TextField
        fullWidth
        id="userEmailOrTel"
        variant="standard"
        placeholder="Pesquisar sorteios"
        onChange={(e) => setSearchText(e.target.value)}
        onKeyUp={(event) => {
          if (event.key === "Enter") {
            search()
          }
        }}
        inputProps={{
          style: {
            padding: 10,
          }
        }}
        InputProps={{
          disableUnderline: true,
          style: {
            background: 'white',
            borderRadius: 5,
            paddingRight: 10
          },
          endAdornment: <IconButton onClick={search}>
            <FaSearch size={20} style={{
              color: 'silver',
            }} />
          </IconButton>,
        }}
      />}

      {/* RIGHT CONTENT */}
      <span style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: 10,
      }}>
        <AppBarUserOptionsComponent
          textColor={settingsState?.payload?.[0]?.theme?.primaryTextColor ?? defaultTheme.primaryTextColor ?? ""}
          imgProps={{
            style: { cursor: 'pointer', },
            onClick: (e) => setAnchorElemnt(e.currentTarget),
          }}
        />

        {!authenticationState.payload ? <IconButton
          sx={{
            color: settingsState?.payload?.[0]?.theme?.primaryTextColor ?? defaultTheme.primaryTextColor,
            display: { md: 'none' }
          }}
          onClick={e => setAnchorElemnt(e.currentTarget)}
        ><MoreIcon></MoreIcon></IconButton> : <></>}
      </span>

    </div >,

    style: {
      background: settingsState?.payload?.[0]?.theme?.primaryColor ?? defaultTheme.primaryColor,
      padding: '10px 0',
      gap: 10,
    },
  }
}