import { IPrizeDraw, IPrizeDrawOffPrice, ITicket } from "../models"

export class ModelUtils {
  static setTotalValue = (ticket: ITicket, prizeDraw: IPrizeDraw) => {
    if (ticket.numbers?.length && prizeDraw) {
      ticket.value = (prizeDraw.price || 0) * ticket.numbers?.length
      ticket.unitValue = prizeDraw.price

      var offPrices = prizeDraw.offPrices?.sort((a: IPrizeDrawOffPrice, b: IPrizeDrawOffPrice) => (b.from || 0) - (a.from || 0))
      var minDate = new Date(-8640000000000000).toISOString();
      var maxDate = new Date(8640000000000000).toISOString();
      var now = new Date().toISOString();

      if (offPrices?.length)
        for (let offPrice of offPrices) {
          if (ticket.numbers.length >= (offPrice.from || 0) && ((offPrice.initDateTime || minDate) <= now) && ((offPrice.endDatetime || maxDate) >= now)) {
            console.log(`preco: ${offPrice.price}`)
            ticket.value = ticket.numbers.length * (offPrice.price || 0)
            ticket.unitValue = offPrice.price
            ticket.offPrice = offPrice
            break
          }
        }
    }

    return ticket
  }

  static getTotalValue = (numbers: number[], prizeDraw: IPrizeDraw) => {
    let value = 0
    let unitValue = 0
    let offPrice = undefined
    if (numbers?.length && prizeDraw) {
      value = (prizeDraw.price || 0) * numbers?.length
      unitValue = prizeDraw.price

      var offPrices = prizeDraw.offPrices?.sort((a: IPrizeDrawOffPrice, b: IPrizeDrawOffPrice) => (b.from || 0) - (a.from || 0))
      var minDate = new Date(-8640000000000000).toISOString();
      var maxDate = new Date(8640000000000000).toISOString();
      var now = new Date().toISOString();

      if (offPrices?.length)
        for (let offPrice of offPrices) {
          if (numbers.length >= (offPrice.from || 0) && ((offPrice.initDateTime || minDate) <= now) && ((offPrice.endDatetime || maxDate) >= now)) {
            console.log(`preco: ${offPrice.price}`)
            value = numbers.length * (offPrice.price || 0)
            unitValue = offPrice.price
            offPrice = offPrice
            break
          }
        }
    }

    return {
      value, unitValue, offPrice
    }
  }
}