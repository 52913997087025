import { TextField } from '@mui/material'
import { FormItemStyled, FormStyled } from 'fwork.react.apptemplate'
import React from 'react'
import { ISetting } from 'rifa.lib.common.ts/models'
import { DEFAULTGAP } from '../../globals'

export const SettingsFinanceComponent = (props: {
  setting: ISetting,
  onChange?: (setting: ISetting) => void
}) => {
  const setting = { ...props.setting }

  return <FormStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}>
    <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}>
      <TextField
        type='number'
        inputProps={{
          readOnly: true,
        }}
        maxRows={10}
        label='PIX (Taxa de entrada)'
        defaultValue={props.setting.pixEntranceFee || ''}
        onChange={(e) => {
          setting.pixEntranceFee = Number(e.target.value)

          if (props.onChange)
            props.onChange(setting)
        }}
      />
    </FormItemStyled>

    <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}>
      <TextField 
        type='number'
        inputProps={{
          readOnly: true,
        }}
        maxRows={10}
        label='PIX (Taxa de saida)'
        defaultValue={props.setting.pixOutFee || ''}
        onChange={(e) => {
          setting.pixOutFee = Number(e.target.value)

          if (props.onChange)
            props.onChange(setting)
        }}
      />
    </FormItemStyled>
  </FormStyled>
}