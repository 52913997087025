import { AppBar, Badge, BadgeProps, Button, IconButton, Toolbar } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { CSSProperties } from "react";
import { CgFileDocument } from 'react-icons/cg';
import { IoMdMenu } from 'react-icons/io';
import { FlexGrowComponent } from "../flexGrow";
import { stylesModule } from './index.styles';

const LeftBtnWrapperStyled = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'none'
  }
}))

const LeftContentWrapperStyled = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none'
  }
}))

const RightBtnWrapperStyled = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    display: 'none'
  }
}))

const RightContentWrapperStyled = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    display: 'none'
  }
}))

export interface IAppBarLeftContentOptions {
  show?: boolean,
  click?: React.MouseEventHandler<HTMLButtonElement>,
  content?: React.ReactNode,
  badgeContent?: React.ReactNode,
  alwaysShow?: boolean,
}

export interface IAppBarRightContentOptions {
  show?: boolean,
  click?: React.MouseEventHandler<HTMLButtonElement>,
  content?: React.ReactNode,
  badgeContent?: React.ReactNode,
  alwaysShow?: boolean,
}

export interface IAppBarOptions {
  show?: boolean,
  style?: CSSProperties,
  mainContent?: React.ReactNode,
  leftContentOptions?: IAppBarLeftContentOptions,
  rightContentOptions?: IAppBarRightContentOptions,
}

// export const AppBarComponent = (props: IAppBarOptions) => {
export const AppBarComponent = React.forwardRef<HTMLDivElement, IAppBarOptions>((props, ref) => {
  const styles = stylesModule(props.style?.minHeight || 55)

  const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: 2,
      top: 5,
      border: `2px solid white`,
      padding: '0px',
      background: 'black',
    },
  }));

  const leftContent = () => {
    return props.leftContentOptions?.content || <IoMdMenu size={25} />
  }

  const leftContentBadge = () => {
    return <StyledBadge badgeContent={props.leftContentOptions?.badgeContent} color='primary'>
      {leftContent()}
    </StyledBadge>
  }

  const rightContent = () => {
    return props.rightContentOptions?.content || <CgFileDocument size={25} />
  }

  const rightContentBadge = () => {
    return <StyledBadge badgeContent={props.rightContentOptions?.badgeContent} color='primary'>
      {rightContent()}
    </StyledBadge>
  }

  return props.show ? <>
    <AppBar 
    ref={ref}
    className="appBarComponent" style={{
      ...styles.root,
      ...props.style
    }}>
      <Toolbar className="toolBar" style={styles.toolBar}>
        {props.leftContentOptions?.show && <>
          <LeftBtnWrapperStyled className="LeftContentWrapperStyled">
            <IconButton className="leftBtn"
              style={styles.iconButton}
              color="inherit"
              onClick={props.leftContentOptions?.click}
            >
              {leftContentBadge()}
            </IconButton>
          </LeftBtnWrapperStyled>

          {(props.leftContentOptions?.badgeContent || props.leftContentOptions?.alwaysShow) && <LeftContentWrapperStyled className='leftContent' style={styles.rightContent}>
            {leftContentBadge()}
          </LeftContentWrapperStyled>}
        </>}

        <FlexGrowComponent>
          {props.mainContent}
        </FlexGrowComponent>

        {props.rightContentOptions?.show && <>
          <RightBtnWrapperStyled className="RightBtnWrapperStyled">
            <IconButton className="rightBtn"
              style={styles.iconButton}
              color="inherit"
              onClick={props.rightContentOptions?.click}
            >
              {rightContentBadge()}
            </IconButton>
          </RightBtnWrapperStyled>

          {(props.rightContentOptions?.badgeContent || props.rightContentOptions?.alwaysShow) && <RightContentWrapperStyled className='rightContent' style={styles.rightContent}>
            {rightContentBadge()}
          </RightContentWrapperStyled>}
        </>}
      </Toolbar>
    </AppBar>
  </> : <></>
})