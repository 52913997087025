import MoreVertIcon from '@mui/icons-material/MoreVert'
import { IconButton, Menu, MenuItem } from "@mui/material"
import React, { useState } from "react"

interface IMoreIcon {
  onClickOpen: () => void,
}

export const MoreIconComponent = ({ onClickOpen }: IMoreIcon) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    event.stopPropagation()
  }

  const handleMenuClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  return <div>
    <IconButton onClick={handleMenuOpen}>
      <MoreVertIcon />
    </IconButton>

    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)} onClose={handleMenuClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}>
      <MenuItem
        style={{
          fontSize: '0.9rem',
        }}
        onClick={(e) => {
          onClickOpen()
          e.stopPropagation()
          setAnchorEl(null)
        }}>Regerar números</MenuItem>
    </Menu>
  </div>
}