import { Box, Button } from "@mui/material"
import { authenticationStateLogout } from "fwork.react.apptemplate"
import { ImageOrInitLettersComponent, ImageOrInitLettersComponentProps } from "fwork.react.apptemplate/src/components/image"
import { IAuthenticationState } from "fwork.react.apptemplate/src/redux/reducers/authenticationtest.slice"
import * as React from 'react'
import { isMobile } from "react-device-detect"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { IUser } from "rifa.lib.common.ts/models"
import { RootState } from "../../../redux/store"
import { RoutesNames, defaultTheme, downloadPrinterApp } from "../../globals"
import { ISettingsState } from "../../../redux/reducers/settings.slice"

export interface IAppBarUserOptionsComponentProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  imgProps?: ImageOrInitLettersComponentProps
}
export const AppBarUserOptionsComponent = (props: {
  textColor: string
} & IAppBarUserOptionsComponentProps) => {
  const { imgProps, ...rest } = props
  const authenticationState: IAuthenticationState<IUser> = useSelector((state: RootState) => state.authenticationState)
  const dispatch = useDispatch<any>()
  const navigate = useNavigate()

  return <div
    {...rest}

    aria-label="AppBarUserInfoComponent"

    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      gap: 10,
      ...props.style
    }}
  >
    {authenticationState.payload?.user ?
      <>
        {isMobile ? <Box sx={{ display: { xs: 'none', md: 'initial' } }}>
          <Button
            variant="contained"
            onClick={downloadPrinterApp}
            style={{
              color: 'inherit',
              textDecoration: 'none',
              whiteSpace: 'nowrap',
            }}>App Impressão
          </Button>
        </Box> : <></>}

        <span style={{
          display: 'flex',
          gap: 10,
          whiteSpace: 'nowrap',
        }}>
          <div style={{ color: props.textColor }}>
            {authenticationState.payload?.user.name + ','}
          </div>
          <Link
            onClick={() => dispatch(authenticationStateLogout())}
            style={{
              color: props.textColor
            }}
            to={RoutesNames.home}>
            Sair
          </Link>
        </span>

        <ImageOrInitLettersComponent
          {...imgProps}

          initLettersText={authenticationState.payload?.user.name}
          onlyFirstAndLastWord={true}
          style={{
            borderRadius: '100%',
            width: 48,
            height: 48,
            objectFit: 'cover',
            background: authenticationState.payload?.user.style?.color,
            // color: authenticationState.payload?.user.style?.textColor,
            color: 'white',
            ...imgProps?.style
          }}
        />
      </> :
      // <Button
      //   variant="contained"
      //   onClick={() => {
      //     dispatch(authenticationStateLogout())
      //     navigate(RoutesNames.login)
      //   }}
      //   style={{
      //     color: 'inherit',
      //   }}>
      //   Sou Adm ou Afiliado
      // </Button>
      <></>
    }
  </div>
}