import createDOMPurify from 'dompurify'
import React from "react"

export const HtmlRawViewComponent = (props: {
  content: String,
  props?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
}) => {

  const DOMPurify = createDOMPurify(window)

  return <div {...props.props} style={{
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    ...props.props?.style
  }}>
    <div dangerouslySetInnerHTML={{
      __html: DOMPurify.sanitize(props.content?.toString() || '')
    }}></div>
  </div>
}