import { FormItemStyled, FormStyled } from 'fwork.react.apptemplate'
import { MuiColorInput } from 'mui-color-input'
import React from 'react'
import { ITheme } from 'rifa.lib.common.ts/models'
import { DEFAULTGAP } from '../../globals'
import { defaultTheme } from '../../../common/globals'

export const SettingsThemeComponent = (props: {
  theme?: ITheme,
  onChange?: (theme: ITheme) => void
}) => {
  let theme: ITheme = { ...props.theme }

  const handleChange = (field: string, value: string) => {
    const themetmp = {
      ...theme,
      [field]: value
    } as ITheme

    if (props.onChange)
      props.onChange(themetmp)
  }

  return <FormStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}>
    <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}>
      <MuiColorInput
        label={"Cor Primária"}
        format="hex8"
        value={theme?.primaryColor ?? defaultTheme.primaryColor!}
        onChange={(value) => {
          handleChange("primaryColor", value)
        }} />
    </FormItemStyled>
    <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}>
      <MuiColorInput
        label={"Cor Primária Texto"}
        format="hex8"
        value={theme?.primaryTextColor ?? defaultTheme.primaryTextColor!}
        onChange={(value) => {
          handleChange("primaryTextColor", value)
        }} />
    </FormItemStyled>
    {/* <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}>
      <MuiColorInput
        label={"Cor Secundária"}
        format="hex8"
        value={theme?.secondaryColor ?? "#FFFFFF"}
        onChange={(value) => {
          handleChange("secondaryColor", value)
        }} />
    </FormItemStyled>
    <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}>
      <MuiColorInput
        label={"Cor Secundária Texto"}
        format="hex8"
        value={theme?.secondaryTextColor ?? "#FFFFFF"}
        onChange={(value) => {
          handleChange("secondaryTextColor", value)
        }} />
    </FormItemStyled> */}
    <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}>
      <MuiColorInput
        label={"Cor do Rodapé"}
        format="hex8"
        value={theme?.footerColor ?? defaultTheme.footerColor!}
        onChange={(value) => {
          handleChange("footerColor", value)
        }} />
    </FormItemStyled>
    <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}>
      <MuiColorInput
        label={"Cor do Texto Rodapé"}
        format="hex8"
        value={theme?.footerTextColor ?? defaultTheme.footerTextColor!}
        onChange={(value) => {
          handleChange("footerTextColor", value)
        }} />
    </FormItemStyled>
  </FormStyled>
}
