import React from 'react'
import { IBanner } from 'rifa.lib.common.ts/models'
import { DEFAULTGAP } from "../../globals"
import { HeaderComponent } from '../miscelaneous/header'
import { BannerComponent } from "./banner"

export const BannersComponent = (props: {
  banners: IBanner[],
  onChange?: (banners: IBanner[]) => void,
  onDelete?: (idx: number) => void
}) => {
  let banners: IBanner[] = [...props.banners]

  return <>
    <HeaderComponent
      props={{
        style: {
          paddingInline: DEFAULTGAP
        }
      }}
      title={'Banners'}
      onAddClick={() => {
        const bannersTmp = [...banners]
        bannersTmp.push({} as IBanner)
        if (props.onChange)
          props.onChange(bannersTmp)
      }} />

    <div style={{
      display: 'flex',
      flexDirection: 'column',
      gap: DEFAULTGAP
    }}>
      {banners?.map((banner, idx) => <BannerComponent
        onTrashClick={() => {
          if (props.onDelete)
            props.onDelete(idx)
        }}
        key={idx}
        banner={banner}
        onChange={(pBanner) => {
          const bannersTmp = [...banners]
          bannersTmp[idx] = pBanner
          if (props.onChange)
            props.onChange(bannersTmp)
        }} />)}
    </div>
  </>
}