import { TextField } from '@mui/material'
import { FormItemStyled, FormStyled, SelectImgComponent } from 'fwork.react.apptemplate'
import React from 'react'
import { FaTrash } from 'react-icons/fa'
import { BannerObjectFitTypes, IBanner } from 'rifa.lib.common.ts/models'
import { DEFAULTGAP } from '../../globals'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import { IDotEnvProductionState } from '../../../redux/reducers/dotenvProduction.slice'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'

export const BannerComponent = (props: {
  banner: IBanner,
  props?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  onChange?: (banner: IBanner,) => void,
  onTrashClick?: () => void
}) => {
  const dotEnvProductionState: IDotEnvProductionState = useSelector((state: RootState) => state.dotEnvProductionState)

  let banner: IBanner = {
    ...props.banner
  }

  return <FormStyled style={{
    flexDirection: 'column',
    rowGap: DEFAULTGAP,
    columnGap: DEFAULTGAP,
  }}>
    <FormItemStyled style={{
      width: 'calc(100% - 10px)',
      flexDirection: 'row',
      alignItems: 'center',
      rowGap: DEFAULTGAP,
      columnGap: DEFAULTGAP,
    }}>
      <div style={{
        position: 'relative',
        width: '100%',
        maxHeight: 350,
        display: 'flex',
        justifyContent: 'center'
      }}>
        <div style={{
          position: 'absolute',
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
          backgroundImage: `url(${banner.url?.indexOf('base64') != -1 ? banner.url?.toString() : `${dotEnvProductionState.payload?.APIURL}/img/${banner.url}`})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          opacity: .4,
          borderRadius: 8
        }}></div>
        <SelectImgComponent
          props={{
            style: {
              width: '100%'
            }
          }}
          id={`bannerImgPicker${Math.random()}`}
          onSelectImg={(src: string) => {
            banner.url = src
            if (props.onChange && banner)
              props.onChange(banner)
          }}
          imgProps={{
            src: banner.url?.indexOf(';base64,') != -1 ? banner.url?.toString() : `${dotEnvProductionState.payload?.APIURL}/img/${banner.url?.toString()}`,
            style: {
              width: '100%',
              height: 200,
              borderRadius: 8,
              objectFit: `${banner.objectFit == 1 ? 'contain' : 'cover'}`,
            }
          }}
        />
      </div>

    </FormItemStyled>
    
    <FormItemStyled style={{
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      rowGap: DEFAULTGAP,
      columnGap: DEFAULTGAP,
    }}>
      <TextField style={{
        width: 'calc(100% - 40px)',
      }}
        label='Título'
        value={banner?.title?.toString() || ''}
        onChange={(e) => {
          banner.title = e.target.value
          if (props.onChange && banner)
            props.onChange(banner)
        }}></TextField>

      <FaTrash color='grey' size={20} onClick={props.onTrashClick} />
    </FormItemStyled>

    <FormItemStyled style={{
      width: '100%',
      rowGap: DEFAULTGAP,
      columnGap: DEFAULTGAP,
    }}>
      <TextField
        style={{
          width: 'calc(100% - 40px)',
        }}
        label='Link url'
        multiline
        maxRows={10}
        value={banner?.link?.toString() || ''}
        onChange={(e) => {
          banner.link = e.target.value
          if (props.onChange && banner)
            props.onChange(banner)
        }}></TextField>
    </FormItemStyled>

    <FormItemStyled style={{
      width: '100%',
      rowGap: DEFAULTGAP,
      columnGap: DEFAULTGAP,
    }}>
      <FormControl>
        <FormLabel id="demo-controlled-radio-buttons-group">Estilo do banner</FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={banner.objectFit || BannerObjectFitTypes.cover}
          onChange={(e: BannerObjectFitTypes | any) => {
            banner.objectFit = e.target.value
            if (props.onChange && banner)
              props.onChange(banner)
          }}
        >
          <FormControlLabel value={BannerObjectFitTypes.cover} control={<Radio />} label="Esticar" />
          <FormControlLabel value={BannerObjectFitTypes.contain} control={<Radio />} label="Manter proporção" />
        </RadioGroup>
      </FormControl>
    </FormItemStyled>
  </FormStyled>
}