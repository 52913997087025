import { ICustomer, IPrizeDraw, ITicket, PaymentStatus } from "rifa.lib.common.ts/models"
import { CommonUtils, NumberUtils, WebUtils } from "fwork-jsts-common/src"
import { ApiClientUtils } from "fwork-jsts-common/src/apiClient"
import { OptionsObject, SnackbarKey, SnackbarMessage } from "notistack"
import { Dispatch } from "react"
import { RoutesNames } from "../../../common/globals"
import { NavigateFunction } from "react-router-dom"
import { toggleLoading } from "../../../redux/reducers/loadingBar.slice"
import { store } from "../../../redux/store"
import axios from '../../../apiClient/axios'
import { ApiRoutesNames } from "rifa.lib.common.ts/api/routes"

type EnqueueSnackbar = (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey

export class PrizeDrawComponentCtrl {
  static getRandomNumber = (previousList: number[], prizeDraw: IPrizeDraw): number => {
    let _number = NumberUtils.randomIntFromInterval(1, prizeDraw.limitCount || 1)
    return previousList.indexOf(_number) !== -1 ? PrizeDrawComponentCtrl.getRandomNumber(previousList, prizeDraw) : _number
  }

  static validateNumbers = (selectedNumers: number[], enqueueSnackbar: EnqueueSnackbar): boolean => {
    if (!selectedNumers.length) {
      enqueueSnackbar('Você deve escolher pelo menos 1 número.', {
        variant: 'error'
      })
      return false
    }

    return true
  }

  static validateCustomer = (customer: ICustomer, selectedNumers: number[], enqueueSnackbar: EnqueueSnackbar): boolean => {
    if (!PrizeDrawComponentCtrl.validateNumbers(selectedNumers, enqueueSnackbar)) return false

    if (!CommonUtils.isValidPhone(customer.phoneNumber?.toString() || '')) {
      enqueueSnackbar('Telefone inválido.', {
        variant: 'error'
      })
      return false
    }

    if (customer.email && !CommonUtils.isValidEmail(customer.email?.toString() || '')) {
      enqueueSnackbar('Email inválido.', {
        variant: 'error'
      })
      return false
    }

    if (!CommonUtils.isValidCPF(customer.cpf?.toString() || '')) {
      enqueueSnackbar('CPF inválido.', {
        variant: 'error'
      })
      return false
    }

    return true
  }

  static validateCount = (selectedNumers: number[], prizeDraw: IPrizeDraw, enqueueSnackbar: EnqueueSnackbar) => {
    if (selectedNumers.length < (prizeDraw.minCount || selectedNumers.length) || selectedNumers.length > (prizeDraw.maxCount || selectedNumers.length)) {
      var msg = 'Você deve escolher uma quantidade de numeros'
      if (prizeDraw.minCount && prizeDraw.maxCount)
        msg += ` entre ${prizeDraw.minCount} e ${prizeDraw.maxCount}`
      else if (prizeDraw.minCount)
        msg += ` maior que ${prizeDraw.minCount}`
      else if (prizeDraw.maxCount)
        msg += ` menor que ${prizeDraw.maxCount}`

      enqueueSnackbar(msg, {
        variant: 'error'
      })
      return false
    }

    return true
  }

  static posting = false

  static post = async (customer: ICustomer, enqueueSnackbar: EnqueueSnackbar, selectedNumers: number[], prizeDraw: IPrizeDraw, navigate: NavigateFunction) => {
    if (PrizeDrawComponentCtrl.posting) {
      enqueueSnackbar('Operação em andamento. Aguarde...', { variant: 'error' })
      return
    }
    PrizeDrawComponentCtrl.posting = true

    if (!PrizeDrawComponentCtrl.validateCustomer(customer, selectedNumers, enqueueSnackbar)) {
      PrizeDrawComponentCtrl.posting = false
      return
    }

    if (!PrizeDrawComponentCtrl.validateCount(selectedNumers, prizeDraw, enqueueSnackbar)) {
      PrizeDrawComponentCtrl.posting = false
      return
    }

    try {
      store.dispatch<any>(toggleLoading())
      const ticket: ITicket = {
        prizeDrawId: prizeDraw._id,
        customer: customer,
        numbers: selectedNumers,
        unitValue: 0,
        dateTime: "",
        value: 0,
        customerId: undefined,
        userId: undefined,
        affiliateCode: WebUtils.getCookie('@affiliateCode')?.replace(/"/g, "")
      }

      const dotEnvProductionState = store.getState().dotEnvProductionState

      const response = await ApiClientUtils.post<ITicket, ITicket>({
        axios,
        apiUrl: `${dotEnvProductionState.payload?.APIURL}/tickets`,
        data: ticket,
      })
      if (response.success) {
        enqueueSnackbar(`${response.data?.payments?.[0].status != PaymentStatus.paid ?
          'Bilhete aguardando pagamento.' : 'Compra realizada com sucesso.'}`, {
          variant: response.data?.payments?.[0].status != PaymentStatus.paid ?
            'warning' : 'success'
        })

        WebUtils.deleteCookie('@affiliateCode')

        navigate(RoutesNames.ticket.replace(':id', response.data?._id))
        
      }
      else {
        enqueueSnackbar(
          response.msg?.indexOf('ECONNREFUSED') != -1
            ? "Não foi possível conectar na API de pagamento"
            : response.msg,
          { variant: "error" }
        )
      }
    }
    catch (error) {
      enqueueSnackbar(`Erro ao comprar rifa. ${error}`, { variant: 'error' })
    }
    finally {
      store.dispatch(toggleLoading())
      PrizeDrawComponentCtrl.posting = false
    }
  }

  static postChangeNumbers = async (token: string, enqueueSnackbar: EnqueueSnackbar, selectedNumers: number[], navigate: NavigateFunction) => {
    if (PrizeDrawComponentCtrl.posting) {
      enqueueSnackbar('Operação em andamento. Aguarde...', { variant: 'error' })
      return
    }
    PrizeDrawComponentCtrl.posting = true

    const dotEnvProductionState = store.getState().dotEnvProductionState

    try {
      store.dispatch(toggleLoading())
      const response = await ApiClientUtils.post<number[], ITicket>({
        axios,
        apiUrl: `${dotEnvProductionState.payload?.APIURL}${ApiRoutesNames.ticketsRenewNumbersConfirm.replace(':token', token)}`,
        data: selectedNumers,
      })

      if (response.success) {
        enqueueSnackbar('Alteração concluida com sucesso.', {
          variant: 'success'
        })
        navigate(RoutesNames.ticket.replace(':id', response.data?._id))
      }
      else {
        enqueueSnackbar(
          response.msg,
          { variant: "error" }
        )
      }
    }
    catch (error) {
      enqueueSnackbar(`Erro ao comprar rifa. ${error}`, { variant: 'error' })
    }
    finally {
      store.dispatch(toggleLoading())
      PrizeDrawComponentCtrl.posting = false
    }
  }
}