import { styled } from "@mui/material"
import { Form10x20Styled, FormItem10x20Styled } from "fwork.react.apptemplate"
import React from "react"
import { ITutorial } from "rifa.lib.common.ts/models"

const ContainerStyled = styled(Form10x20Styled)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    paddingBottom: 120
  }
}))

type ItemStyledProps = {
  width: number
}

const ItemStyled = styled(FormItem10x20Styled)<ItemStyledProps>(({ theme, width }) => ({
  width: '100% !important',
  position: 'relative',
  [theme.breakpoints.up('md')]: {
    width: `calc(${width}% - 20px) !important`
  }
}))

export const TutorialComponent = (props: {
  tutorials?: ITutorial[]
}) => {
  return <ContainerStyled className="TutorialComponent" style={{
    minHeight: 220,
  }}>
    {props.tutorials?.map((tutorial, index) => <ItemStyled key={index} width={props.tutorials?.length ? (100 / props.tutorials?.length) : 0}>
      <span style={{
        position: 'absolute',
        fontSize: '10rem',
        fontWeight: 'bold',
        color: '#e8e9e9',
        top: 0,
        left: 0,
        opacity: .4,
      }}>{(index + 1)}</span>
      
      <h2 style={{
        color: '#515151',
        zIndex: 1,
        overflow: 'hidden'
      }}>
        <i aria-hidden="true"></i>
        {tutorial.title}
      </h2>
      <p style={{
        color: '#4c4c4c',
        zIndex: 1,
        overflow: 'hidden'
      }}>
        {tutorial.text}
      </p>
    </ItemStyled>)}
  </ContainerStyled>
}