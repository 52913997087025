import { Box, Tab, Tabs } from "@mui/material"
import React, { ReactNode, SyntheticEvent, useState } from "react"
import { IPrizeDrawAwardedNumber, IPrizeDrawOffPrice } from "rifa.lib.common.ts/models"
import { PrizeDrawAwardedNumbersComponent } from "../prizeDrawAwardedNumbers"
import { PrizeDrawOffPricesComponent } from "../prizeDrawOffPrices"

interface TabPanelProps {
  children?: ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  )
}

function tabProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}


export interface IPrizeDrawAwardedNumbersProps {
  awardedNumbers: IPrizeDrawAwardedNumber[]
  onChange?: (awardedNumbers: IPrizeDrawAwardedNumber[]) => void
  onDelete?: (idx: number) => void
}


export interface IPrizeDrawOffPricesProps {
  offPrices: IPrizeDrawOffPrice[]
  onChange?: (offPrices: IPrizeDrawOffPrice[]) => void
  onDelete?: (idx: number) => void
}

export const PrizeDrawAdditionalContainerComponent = (props: {
  prizeDrawAwardedNumbersProps: IPrizeDrawAwardedNumbersProps,
  prizeDrawOffPricesProps: IPrizeDrawOffPricesProps
}) => {
  const [currTab, setCurrTab] = useState(0)

  const onTabChange = (event: SyntheticEvent, newTabIndex: number) => {
    setCurrTab(newTabIndex)
  }

  return (
    <Box sx={{ width: '100%', }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={currTab} onChange={onTabChange}>
          <Tab label="Promoções" {...tabProps(0)} />
          <Tab label="Cotas Premiadas" {...tabProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={currTab} index={0}>
        <PrizeDrawOffPricesComponent {...props.prizeDrawOffPricesProps} />
      </TabPanel>
      <TabPanel value={currTab} index={1}>
        <PrizeDrawAwardedNumbersComponent {...props.prizeDrawAwardedNumbersProps} />
      </TabPanel>
    </Box>
  );
}