import { Button, styled } from "@mui/material";
import { FormItem10x20Styled } from "fwork.react.apptemplate";

export const PrizeDrawItemCardWrapperStyled = styled(FormItem10x20Styled, {
  shouldForwardProp: (prop) => prop !== 'forceFullWidth', // Não envia 'forceFullWidth' para o DOM
})<{
  forceFullWidth: Boolean
}>(({ theme, forceFullWidth }) => ({
  order: 1,
  width: 'calc(100% - 10px)',
  [theme.breakpoints.up('sm')]: {
    width: 'calc(100% - 20px)'
  },
  [theme.breakpoints.up('md')]: {
    width: forceFullWidth ? '100%' : 'calc(70% - 20px)'
  }
}))

export const PrizeDrawAdditionalInfoStyled = styled(FormItem10x20Styled)(({ theme }) => ({
  order: 2,
  width: '100%',
  borderRadius: 5,
  boxShadow: 'rgb(0 0 0 / 16%) 1px 1px 3px',
  background: 'white',
  overflow: 'hidden',
  [theme.breakpoints.up('md')]: {
    order: 3,
    width: '100%'
  }
}))

export const PrizeDrawOffPricesInfoStyled = styled(FormItem10x20Styled)(({ theme }) => ({
  order: 2,
  borderRadius: 5,
  boxShadow: 'rgb(0 0 0 / 16%) 1px 1px 3px',
  overflow: 'hidden',
  [theme.breakpoints.up('md')]: {
    order: 3,
    flexGrow: 1,
  }
}))

export const PrizeDrawOptionsWrapperStyled = styled(FormItem10x20Styled)(({ theme }) => ({
  order: 3,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
  [theme.breakpoints.up('md')]: {
    order: 2,
    width: 'calc(30% - 20px)'
  }
}))

export const PrizeDrawOptionsStyled = styled('div')(({ theme }) => ({
  borderRadius: 5,
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: 10,
  flexGrow: 1,
}))

export const PrizeDrawOptionsItemStyled = styled(Button)(({ theme }) => ({
  background: '#484848',
  color: 'white',
  width: 'calc(30% - 5px)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  borderRadius: 5,
  minHeight: 50,
  [theme.breakpoints.up('md')]: {
    minHeight: 0,
    width: 'calc(50% - 5px)',
  },
  flexGrow: 1,
  fontSize: 20,
  overflow: 'hidden',
}))