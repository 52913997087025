import { styled } from '@mui/material'

export const DefaultCardComponent = styled('div')(({ theme }) => ({
    background: 'white',
    color: '#404040',
    boxShadow: 'rgb(0 0 0 / 16%) 1px 1px 3px',
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    padding: '0 15px 15px 0',
    boxSizing: 'border-box'
}))