import { ApiClientUtils } from 'fwork-jsts-common/src/apiClient'
import { FilterQuery } from 'mongoose'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { IPrizeDraw } from 'rifa.lib.common.ts/models'
import axios from '../../../apiClient/axios'
import { PrizeDrawsApiClient } from '../../../apiClient/prizeDraws'
import { RoutesNames } from '../../../common/globals'
import { IDotEnvProductionState } from '../../../redux/reducers/dotenvProduction.slice'
import { RootState } from '../../../redux/store'
import { DEFAULTGAP } from '../../globals'
import { HeaderComponent } from '../miscelaneous/header'
import { ResultSetItemComponent } from '../miscelaneous/resultSetItem'

export const PrizeDrawsComponent = (props: {
  data: IPrizeDraw[] | undefined,
}) => {
  const navigate = useNavigate()
  const [localPrizeDraws, setLocalPrizeDraws] = useState<IPrizeDraw[] | undefined>()
  const { enqueueSnackbar } = useSnackbar()
  const dotEnvProductionState: IDotEnvProductionState = useSelector((state: RootState) => state.dotEnvProductionState)

  useEffect(() => {
    if (!localPrizeDraws?.length)
      loadData()
  }, [])

  const loadData = async () => {
    try {
      let where: FilterQuery<IPrizeDraw> | undefined

      const response = await new PrizeDrawsApiClient(axios).get({
        where
      })

      if (response.success) {
        setLocalPrizeDraws(response.data?.payload)
      }

    } catch (error) {
      enqueueSnackbar(ApiClientUtils.getErrorMessage(error), { variant: 'error' })
    }
  }

  return <>
    <HeaderComponent
      props={{
        style: {
          paddingInline: DEFAULTGAP
        }
      }}
      title='Sorteios'
      onAddClick={() => {
        navigate(RoutesNames.adminPrizedraw.replace(':id', 'new'))
      }}
      onRefreshClick={loadData}
    />

    {localPrizeDraws?.map(prizeDraw => <ResultSetItemComponent
      key={prizeDraw._id}
      showImg={true}
      imgProps={{
        src: `${dotEnvProductionState.payload?.APIURL}/img/${prizeDraw.imgUrl?.toString()}`,
        style: {
          borderRadius: 5
        }
      }}
      label={`${prizeDraw.title}`}
      obj={prizeDraw}
      onClick={(obj) => {
        navigate(RoutesNames.adminPrizedraw.replace(':id', prizeDraw._id))
      }} />)}

  </>
}