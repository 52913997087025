import { Link } from "@mui/material"
import { FlexGrowComponent } from "fwork.react.apptemplate"
import { ImageOrInitLettersComponent } from "fwork.react.apptemplate/src/components/image"
import moment from "moment"
import React, { useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from 'react-router-dom'
import { ITicket, PaymentStatus } from "rifa.lib.common.ts/models"
import { IDotEnvProductionState } from "../../../redux/reducers/dotenvProduction.slice"
import { RootState } from "../../../redux/store"
import { RoutesNames } from "../../globals"
import { TicketStatusComponent } from "../../../site/components/ticket/status"

enum TicketItemNumberType {
  none,
  win,
  lost,
}

export const enum TicketItemType {
  user,
  prizeDraw,
}

const TicketSmallComponent = (props: {
  number: number,
  type: TicketItemNumberType,
  padLen: number
}) => {
  return <div style={{
    background: props.type == TicketItemNumberType.win ? 'green' : props.type == TicketItemNumberType.lost ? 'red' : 'grey',
    color: 'white',
    borderRadius: 5,
    paddingBlock: 3,
    paddingInline: 5,
    width: 70,
    textAlign: 'center',
  }}>
    {String(props.number).padStart(props.padLen, '0')}
  </div>
}

export const TicketItemCardComponent = (props: {
  ticket: ITicket,
  type: TicketItemType,
  props?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  showPaymentStatus?: boolean,
}) => {

  const checkDrawnNumbers = (arrayA: Number[], arrayB: Number[]) => arrayA.some(r => arrayB.indexOf(r) >= 0)
  const dotEnvProductionState: IDotEnvProductionState = useSelector((state: RootState) => state.dotEnvProductionState)
  const navigate = useNavigate()
  const checkedDrawnNumbers = checkDrawnNumbers(props.ticket.numbers || [], props.ticket.prizeDraw?.drawnNumbers || [])
  const [showAllNumbers, setShowAllNumbers] = useState<boolean | undefined>()

  const showNumbers = props.ticket.prizeDraw?.showSelectedNumbers
    && (!props.ticket.prizeDraw?.showNumbersOnlyPaidInSale || props.ticket.payments![0].status == PaymentStatus.paid);

  const transaction = props.ticket.payments?.length ? props.ticket.payments[0] : null;
  const qrExpiration = transaction?.expirationDate ? moment(transaction?.expirationDate.toString()) : null
  const remainingQrExpirationSecs = transaction?.status === PaymentStatus.waitingPayment ?
    moment.duration(qrExpiration?.diff(new Date())).asSeconds() : 0

  const showQrExpired = props.ticket.payments![0].status === PaymentStatus.waitingPayment && remainingQrExpirationSecs <= 0

  return <div {...props.props} style={{
    background: 'white',
    boxShadow: 'rgb(0 0 0 / 16%) 1px 1px 3px',
    borderRadius: 5,
    padding: 10,
    gap: 10,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    cursor: 'pointer',
    textDecoration: props.ticket.canceled ? 'line-through' : 'none',
    color: props.ticket.canceled ? 'red' : 'none',
    ...props.props?.style
  }}

    onClick={(e) => {
      if (props.props?.onClick)
        props.props.onClick(e)

      navigate(RoutesNames.ticket.replace(':id', props.ticket._id))
    }}
  >
    <div style={{
      display: 'flex',
      gap: 10
    }}>
      <ImageOrInitLettersComponent
        src={`${dotEnvProductionState.payload?.APIURL}/img/${props.type == TicketItemType.prizeDraw ?
          props.ticket.prizeDraw?.imgUrl?.toString() : props.ticket.customer?.imgProfileUrl?.toString()}`}
        initLettersText={props.type == TicketItemType.prizeDraw ? props.ticket.prizeDraw?.title : props.ticket.customer?.name}
        onlyFirstAndLastWord={true}
        style={{
          width: 100,
          height: 100,
          objectFit: 'cover',
          borderRadius: props.type == TicketItemType.prizeDraw ? 5 : '100%',
          // color: props.ticket.customer?.style?.textColor,
          color: 'white',
          background: props.ticket.customer?.style?.color,
          fontSize: 45,
        }}
      />

      <div style={{
        width: 'calc(100% - 100px - 10px)',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        height: 'fit-content',
        alignItems: 'center',
        gap: 6,
        borderRadius: 5,
      }}>

        <div style={{ width: '100%', }}>{props.type == TicketItemType.prizeDraw ? props.ticket.prizeDraw?.title : `${props.ticket.customer?.name} (${props.ticket.customer?.email})`}
        </div>

        <div style={{ width: '100%', }}>
          {props.type == TicketItemType.prizeDraw ? props.ticket.prizeDraw?.limitDateTime &&
            `Data sorteio: ${moment(props.ticket.prizeDraw?.limitDateTime as string).format('DD/MM/YYYY HH:mm')}` : props.ticket.customer?.phoneNumber}
        </div>

        <div style={{ width: '100%', }}>
          {`Data da Compra: ${moment(props.ticket.dateTime as string).format('DD/MM/YYYY HH:mm')}`}
        </div>

        {props.ticket.prizeDraw?.closed ? <div style={{
          background: 'blue',
          color: 'white',
          padding: '3px 5px',
          borderRadius: 5,
          width: 'fit-content',
        }}>ENCERRADO</div> : <></>}

        {props.ticket.prizeDraw?.closed ? <div style={{
          background: checkedDrawnNumbers ? 'green' : 'red',
          color: 'white',
          padding: '3px 5px',
          borderRadius: 5,
          width: 'fit-content',
        }}>{checkedDrawnNumbers ? 'GANHOU' : 'PERDEU'}</div> : <></>}
        {/* nao pode usar sort aqui, tem que aparecer na ordem que foram sorteados */}
        {props.ticket.prizeDraw?.closed ? props.ticket.prizeDraw?.drawnNumbers?.map((n, idx) => <TicketSmallComponent key={idx}
          type={TicketItemNumberType.none}
          number={Number(n)}
          padLen={props.ticket.prizeDraw?.limitCount.toString().length ?? 7}
        />) : <></>}
      </div>
    </div>

    {props.showPaymentStatus && props.ticket.payments?.length && <TicketStatusComponent transaction={props.ticket.payments[0]} />}

    {props.ticket.numbers?.length ? (props.ticket.numbers?.length || 0) > 1 ? <div>{props.ticket.numbers?.length} números escolhidos</div> : <div>{props.ticket.numbers?.length} número escolhido</div> : <></>}

    {showNumbers ?
      <div style={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: 10
      }}>
        {props.ticket.numbers?.slice(0, showAllNumbers ? props.ticket.numbers.length : 5)?.sort((a, b) => a - b)?.map((n, idx) => <TicketSmallComponent
          key={idx}
          type={props.ticket.prizeDraw?.closed && props.ticket.prizeDraw?.drawnNumbers?.filter(x => x == n).length ?
            TicketItemNumberType.win :
            props.ticket.prizeDraw?.closed ?
              TicketItemNumberType.lost : TicketItemNumberType.none}
          number={Number(n)}
          padLen={props.ticket.prizeDraw?.limitCount.toString().length ?? 7}
        />)}
      </div> : <></>}

    {
      (props.ticket.numbers?.length || 0) > 5 &&
        showNumbers ? <Link style={{
          color: '#404040',
          textDecoration: 'none',
          fontWeight: 'bold',
          cursor: 'pointer'
        }} onClick={() => setShowAllNumbers(!showAllNumbers)}>{showAllNumbers ? '...menos' : 'Mais...'}</Link> : <></>
    }

    <FlexGrowComponent />
  </div >
}