import { IconButton, TextField } from '@mui/material'
import { FormItemStyled, FormStyled } from 'fwork.react.apptemplate'
import React from 'react'
import { FaTrash } from 'react-icons/fa'
import { ITutorial } from 'rifa.lib.common.ts/models'
import { DEFAULTGAP } from '../../globals'

export const TutorialComponent = (props: {
  tutorial: ITutorial,
  props?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  onChange?: (tutorial: ITutorial,) => void,
  onTrashClick?: () => void
}) => {
  let tutorial: ITutorial = {
    ...props.tutorial
  }

  return <div style={{ border: '1px solid #C4C4C4', display: 'flex', justifyContent: 'space-between', background: 'white', padding: DEFAULTGAP, borderRadius: 10 }}>
    <FormStyled style={{
      flexGrow: 1
    }}>
      <FormItemStyled>
        <TextField
          label='Título'
          value={tutorial?.title?.toString() || ''}
          onChange={(e) => {
            tutorial.title = e.target.value
            if (props.onChange && tutorial)
              props.onChange(tutorial)
          }}></TextField>


      </FormItemStyled>

      <FormItemStyled style={{
        flexGrow: 1
      }}>
        <TextField
          label='Texto'
          multiline
          maxRows={10}
          value={tutorial?.text?.toString() || ''}
          onChange={(e) => {
            tutorial.text = e.target.value
            if (props.onChange && tutorial)
              props.onChange(tutorial)
          }}></TextField>
      </FormItemStyled>
    </FormStyled>
    <IconButton style={{ height: 46, width: 46 }}><FaTrash style={{ cursor: 'pointer' }} color='grey' size={20} onClick={props.onTrashClick} /></IconButton>
  </div>
}