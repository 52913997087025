import { DefaultPageComponent as DefaultPage } from 'fwork.react.apptemplate'
import React, { CSSProperties } from "react"
import { appBarOptions } from "../appBar"
import { MainMenuComponent } from "../mainMenu"

export const DefaultPageComponent = (props: {
  children?: React.ReactNode,
  style?: CSSProperties,
}) => {
  return <DefaultPage
    style={props.style}

    appBarOptions={appBarOptions()}

    leftDrawerOptions={{
      content: <MainMenuComponent />,
    }}

    leftAnchorOptions={{
      content: <MainMenuComponent />
    }}

    mainAnchorOptions={{
      content: <div style={{
        height: '100%',
        boxSizing: 'border-box',
      }}>
        {props.children}
      </div>

    }}
  />
}