import { FormControlLabel, Radio, RadioGroup, styled } from '@mui/material'
import { FormItemStyled, FormStyled } from "fwork.react.apptemplate"
import React, { useState } from "react"
import { DEFAULTGAP } from "../../globals"
import { HeaderComponent } from "../miscelaneous/header"
import { ChartPrizedrawsTicketCount } from './chartPrizedrawsTicketCount'

export enum StatisticsTypes {
  all,
  hideCanceled,
  showOnlyCanceled,
}

const CardStyled = styled(FormItemStyled)(({ theme }) => ({
  overflow: 'hidden',
  background: 'white',
  color: '#404040',
  boxShadow: 'rgb(0 0 0 / 16%) 1px 1px 3px',
  borderRadius: 5,
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  padding: 10,
}))

export const DashboardComponent = () => {
  const [prizeDrawFilter, setPrizeDrawFilter] = useState(StatisticsTypes.hideCanceled)

  return <>
    <HeaderComponent props={{
      style: {
        paddingInline: DEFAULTGAP
      }
    }} title='Estatísticas' />

    <FormItemStyled>
      <RadioGroup
        row
        name="controlled-radio-buttons-group"
        value={prizeDrawFilter}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setPrizeDrawFilter(Number((event.target as HTMLInputElement).value))
        }}>
        <FormControlLabel value={StatisticsTypes.all} control={<Radio />}
          label="Exibir todos" />
        <FormControlLabel value={StatisticsTypes.hideCanceled} control={<Radio />}
          label="Ocultar cancelados" />
        <FormControlLabel value={StatisticsTypes.showOnlyCanceled} control={<Radio />}
          label="Exibir cancelados" />
      </RadioGroup>
    </FormItemStyled>

    <FormStyled>
      <CardStyled>
        <ChartPrizedrawsTicketCount filter={prizeDrawFilter} />
      </CardStyled>
    </FormStyled>
  </>
}