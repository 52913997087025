import styled from "@emotion/styled"
import { Button } from "@mui/material"
import { IAuthenticationState } from "fwork.react.apptemplate"
import React from "react"
import { BsCardList } from 'react-icons/bs'
import { HiUsers } from 'react-icons/hi'
import { IoIosHome, IoIosSettings } from "react-icons/io"
import { useSelector } from "react-redux"
import { useNavigate } from 'react-router-dom'
import { IUser, UserTypes } from "rifa.lib.common.ts/models"
import { RoutesNames } from "../../../common/globals"
import { RootState } from "../../../redux/store"
import { HomeTypes } from "../../types"

const MenuItemStyled = styled(Button)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 10,
  padding: 10,
  borderRadius: 0,
  justifyContent: 'flex-start',
  whiteSpace: 'nowrap',
  width: '100%',
  color: 'white'
}))

export const MainMenuComponent = (props: {
  props?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  onItemClick?: (homeType: HomeTypes) => void
}) => {
  const navigate = useNavigate()
  const authenticationState: IAuthenticationState<IUser> = useSelector((state: RootState) => state.authenticationState)
  
  return <div style={{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    background: '#1E3050',
    height: '100%',
    boxSizing: 'border-box'
  }} {...props.props}>
    <MenuItemStyled onClick={() => {
      navigate(RoutesNames.home)
    }}><IoIosHome /> Site</MenuItemStyled>

    {authenticationState.payload?.user.userTypes?.includes(UserTypes.admin) ? <>
      <MenuItemStyled onClick={() => {
        if (props.onItemClick)
          props.onItemClick(HomeTypes.settings)
        navigate(RoutesNames.adminSettings)
      }}><IoIosSettings /> Configurações</MenuItemStyled>

      <MenuItemStyled onClick={() => {
        if (props.onItemClick)
          props.onItemClick(HomeTypes.users)
        navigate(RoutesNames.adminUsers)
      }}><HiUsers />Usuarios</MenuItemStyled>

      <MenuItemStyled onClick={() => {
        if (props.onItemClick)
          props.onItemClick(HomeTypes.prizeDraws)
        navigate(RoutesNames.adminPrizedraws)
      }}><BsCardList /> Sorteios</MenuItemStyled>
    </> : <></>}

    <MenuItemStyled onClick={() => {
      if (props.onItemClick)
        props.onItemClick(HomeTypes.prizeDrawsMovement)
      navigate(RoutesNames.adminPrizedrawsMovement)
    }}><BsCardList /> Movimentação</MenuItemStyled>
  </div>
}