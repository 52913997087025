import { styled } from "@mui/material";

export interface TicketWinnersTitleStyledProps {
  alignUpSm?: string,
}

export const TicketWinnersTitleStyled = styled('h2')<TicketWinnersTitleStyledProps>
  (({ theme, alignUpSm }) => ({
    justifyContent: 'center',
    marginLeft: '20px',
    marginBlock: 40,
    display: 'flex',
    alignItems: 'center',
    color: '#404040',
    [theme.breakpoints.up('sm')]: {
      justifyContent: alignUpSm || 'left',
    },
  }))

export const TicketWinnersStyled = styled('div')({
  display: 'flex',
  flexDirection: 'column',
})