import { ApiClientUtils } from "fwork-jsts-common/src/apiClient"
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from "react"
import Chart from "react-google-charts"
import { IPrizeDraw } from "rifa.lib.common.ts/models"
import { StatisticsTypes } from "."
import axios from "../../../apiClient/axios"
import { PrizeDrawsApiClient } from "../../../apiClient/prizeDraws"

interface IChartProps {
  filter: StatisticsTypes
}

export const ChartPrizedrawsTicketCount = ({ filter }: IChartProps) => {
  const [data, setData] = useState<any[]>()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    loadData()
  }, [filter])

  const filterPrizeDraws = (prizeDraws: IPrizeDraw[], selectedFilter: StatisticsTypes) => {
    return prizeDraws?.filter(prizeDraw => {
      if (selectedFilter == StatisticsTypes.all) return true // Todos
      if (selectedFilter == StatisticsTypes.hideCanceled) return !prizeDraw.closed // Ativos
      if (selectedFilter == StatisticsTypes.showOnlyCanceled) return prizeDraw.closed // Cancelados/Encerrados
    }) || []
  }

  const loadData = async () => {
    try {
      const response = await new PrizeDrawsApiClient(axios).get()

      if (response.success) {
        const titles = [
          'Sorteios',
          'Limite de Números',
          'Quantidade Vendida',
          'Porcentagem Vendida (%)'
        ]

        const filteredData = filterPrizeDraws(response.data?.payload || [], filter).map(prizeDraw => [
          prizeDraw.title?.substring(0, 15),
          prizeDraw.limitCount,
          prizeDraw.paydSoldNumbersCount,
          (prizeDraw.paydSoldNumbersCount || 0) / prizeDraw.limitCount * 100
        ])


        !filteredData.length ? setData([]) : setData([
          titles,
          ...filteredData
        ])

      } else {
        enqueueSnackbar(response.msg, { variant: 'error' })
      }
    } catch (error) {
      enqueueSnackbar(ApiClientUtils.getErrorMessage(error), { variant: 'error' })
    }
  }
  const options = {
    title: "Estatísticas de Vendas",
    hAxis: {
      title: 'Sorteios',
    },
    vAxis: {
      title: 'Total de Cotas',
    },
    seriesType: 'bars',
    series: {
      0: { color: '#ffab91' }, //limite de números
      1: { color: '#5bc0de' }, //quantidade vendida
      2: { type: 'line', color: '#F55555' }  //porcentagem vendida
    },
  }

  return <>
    {!data?.length ? <p>Não há dados disponíveis para esta consulta</p> : <Chart
      chartType="ComboChart"
      width="100%"
      height="400px"
      data={data}
      options={options}
    />}
  </>
}