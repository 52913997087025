import moment from 'moment'
import React, { useState, useEffect } from 'react'

type Props = {
  interval: number,
  text?: string,
  style?: React.CSSProperties,
  onReset?: () => void,
  hideText?: boolean,
  timeFormat?: string
}

const DownTimerComponent = ({ interval, text, style, onReset, hideText, timeFormat }: Props) => {
  const [[secs], setCountdown] = useState([interval])

  useEffect(() => {
    let timerInterval = setInterval(() => {
      if (secs === 0) {
        setCountdown([interval])
        if (onReset)
          onReset()
      } else {
        setCountdown([secs - 1])
      }
    }, 1000)

    return () => {
      clearInterval(timerInterval)
    }
  }, [secs])

  return !hideText ? <div style={style}>
    {text} {moment.utc(secs*1000).format(timeFormat || 'HH:mm:ss')}
  </div> : <></>
}

export default DownTimerComponent