import { IconButton, styled } from "@mui/material"
import { DefaultPageComponent as DefaultPage, FlexGrowComponent } from "fwork.react.apptemplate"
import React, { CSSProperties } from "react"
import { appBarOptions, ICustomAppBarOptions } from "../appBar"
import { FooterComponent } from "../footer"

export const SizedContainerStyled = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('xl')]: {
    paddingLeft: 'calc((100% - 80%)/2)',
    paddingRight: 'calc((100% - 80%)/2)'
  }
}))

export const DefaultPageComponent = (props: {
  content?: React.ReactNode,
  style?: CSSProperties,
  appBarOptions?: ICustomAppBarOptions,
  showTutorial?: boolean,
  hideFlexGrow?: boolean
}) => {
  return <DefaultPage
    appBarOptions={appBarOptions(props.appBarOptions)}

    mainAnchorOptions={{
      content: <div style={{
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}>
        {props.content}
        {!props.hideFlexGrow ? <FlexGrowComponent /> : <></>}
        <FooterComponent showTutorial={props.showTutorial} />
      </div>
    }}

    style={{
      background: '#F1F0F0',
      ...props.style
    }}
  />
}