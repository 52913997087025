import { Divider, IconButton } from "@mui/material"
import { FlexGrowComponent, FormItemStyled, FormStyled } from "fwork.react.apptemplate"
import React from "react"
import { FaHome } from "react-icons/fa"
import { useSelector } from "react-redux"
import { Link, useLocation } from 'react-router-dom'
import { ITutorial } from "rifa.lib.common.ts/models"
import { defaultTheme, RoutesNames, VERSION } from "../../../common/globals"
import { ISettingsState } from "../../../redux/reducers/settings.slice"
import { RootState } from "../../../redux/store"
import { SizedContainerStyled } from "../defaultPage"
import { SocialMediaComponent } from "../socialMedia"
import { TutorialComponent } from "./tutorial"
import { RiWhatsappFill } from "react-icons/ri";
import { CommonUtils, NumberUtils } from "fwork-jsts-common/src"

export const FooterComponent = (props: {
  showTutorial?: boolean
}) => {
  const settingsState: ISettingsState = useSelector((state: RootState) => state.settingsState)
  const location = useLocation()

  const showTutorial = props.showTutorial && (settingsState.payload?.length ? settingsState.payload[0].tutorials?.length : false)
  let tutorials: ITutorial[] | undefined
  if (settingsState.payload?.length)
    if (settingsState.payload[0].tutorials?.length)
      tutorials = settingsState.payload[0].tutorials

  return <>
    {showTutorial ? <SizedContainerStyled className="SizedContainerStyled" style={{
      background: '#cecece'
    }}>
      <TutorialComponent tutorials={tutorials} />
    </SizedContainerStyled> : <></>}

    <SizedContainerStyled className="SizedContainerStyled" style={{
      background: settingsState?.payload?.[0]?.theme?.footerColor ?? defaultTheme.footerColor,
      color: settingsState?.payload?.[0]?.theme?.footerTextColor ?? defaultTheme.footerTextColor,
      boxSizing: 'border-box',
    }}>
      <div style={{ padding: 10, display: 'flex', gap: 10, minHeight: 180, }}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 10, }}>
          <div style={{ display: 'flex', gap: 10, alignItems: 'start' }}>
            {location.pathname != RoutesNames.home && settingsState.payload?.[0].showSitePath ? <Link style={{
              textDecoration: 'none',
              color: settingsState?.payload?.[0]?.theme?.footerTextColor ?? defaultTheme.footerTextColor,
              display: 'flex',
              gap: 10,
              alignItems: 'center',
            }} to={RoutesNames.home}><FaHome />Home</Link> : <></>}


            <div style={{ display: 'flex', gap: 10, flexDirection: 'column' }}>
              <Link style={{
                textDecoration: 'none',
                color: settingsState?.payload?.[0]?.theme?.footerTextColor ?? defaultTheme.footerTextColor,
                whiteSpace: 'nowrap'
              }} to={RoutesNames.useThermes}>Termos de uso</Link>

              <Link style={{
                textDecoration: 'none',
                color: settingsState?.payload?.[0]?.theme?.footerTextColor ?? defaultTheme.footerTextColor,
                whiteSpace: 'nowrap'
              }} to={RoutesNames.privacyPolicy}>Política de privacidade</Link>
            </div>
          </div>

          <Divider style={{ width: '100%' }} />

          <div style={{display: 'flex', flexDirection: 'column', gap: 10, flexGrow: 1 }}>
            <SocialMediaComponent />

            <FlexGrowComponent />

            <label style={{ fontSize: 10 }}>{VERSION}</label>
            <label style={{ fontSize: 10 }}>Desenvolvido por SL7 Tecnologia</label>
          </div>
        </div>

        {settingsState.payload?.length ? <img alt="" src={settingsState.payload[0].companyLogoUrl?.toString()} style={{
          width: 100, height: 100, objectFit: 'contain'
        }} /> : <></>}
      </div>
    </SizedContainerStyled>

    {!CommonUtils.isNullOrEmpty(settingsState.payload?.[0]?.whatsapp) ? <a style={{ display: 'flex' }} href={`https://wa.me/${NumberUtils.onlyNumbers(settingsState.payload?.[0]?.whatsapp)}`} target='_blank'>
      <IconButton
        style={{
          position: 'fixed',
          bottom: 20,
          right: 20,
          zIndex: 10000000,
          background: 'white',
          boxShadow: '0px 0px 25px 5px rgba(0,0,0,0.29)'
        }}
      >
        <RiWhatsappFill color="#25D366" size={40} />
      </IconButton>
    </a> : <></>}

  </>
}