import { IconButton, TextField } from "@mui/material"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import { FormItemStyled, FormStyled } from "fwork.react.apptemplate"
import moment from "moment"
import React from "react"
import { FaTrash } from "react-icons/fa"
import { IPrizeDrawAwardedNumber } from "rifa.lib.common.ts/models"
import { DateTimePickerExtComponent } from "../../../common/components/datePicker"
import { DEFAULTGAP } from "../../globals"

export const PrizeDrawAwardedNumberComponent = (props: {
  awardedNumber?: IPrizeDrawAwardedNumber,
  onChange?: (prizeDrawAwarded: IPrizeDrawAwardedNumber) => void,
  onTrashClick?: () => void,
}) => {
  let awarded: IPrizeDrawAwardedNumber = {
    ...props.awardedNumber,
  } as IPrizeDrawAwardedNumber

  const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    awarded.number = Number(e.target.value)
    if (props.onChange && awarded) {
      props.onChange(awarded)
    }
  }

  const handleAwardValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    awarded.awardValue = Number(e.target.value)
    if (props.onChange && awarded) {
      props.onChange(awarded)
    }
  }

  const handleDateChange = (value: any) => {
    awarded.validFromDateUnix = moment(value).unix()
    if (props.onChange && awarded) {
      props.onChange(awarded)
    }
  }

  return <div style={{ border: '1px solid #C4C4C4', display: 'flex', justifyContent: 'space-between', background: 'white', padding: DEFAULTGAP, borderRadius: 10 }}>
    <FormStyled >
      <FormItemStyled>
        <TextField style={{
          background: 'white'
        }}
          label='Numero premiado'
          type='number'
          value={awarded.number || ''}
          onChange={handleNumberChange}
        />

      </FormItemStyled>

      <FormItemStyled>
        <TextField
          style={{
            background: 'white'
          }}
          label='Valor da premiação'
          type='number'
          value={awarded?.awardValue || ''}
          onChange={handleAwardValueChange}
        />
      </FormItemStyled>

      <FormItemStyled>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DateTimePickerExtComponent
            // InputProps={{
            //   style: {
            //     background: 'white'
            //   }
            // }}
            ampm={false}
            // inputFormat='DD/MM/YYYY HH:mm'
            label="Data inicial"
            // renderInput={(params) => <TextField {...params} />}
            value={moment.unix(awarded?.validFromDateUnix || 0)} // Converte Unix timestamp para Moment
            onChange={handleDateChange}
          />
        </LocalizationProvider>
      </FormItemStyled>
    </FormStyled>

    <IconButton style={{ height: 46, width: 46 }}><FaTrash style={{ cursor: 'pointer' }} color='grey' size={20} onClick={props.onTrashClick} /></IconButton>
  </div>
}
