import React from 'react'
import { BreadcrumbComponent } from '../../../common/components/breadcrumb'
import { DefaultPageComponent } from '../../components/defaultPage'
import { TicketComponent } from '../../components/ticket'
import { useParams } from 'react-router-dom'

export const TicketPage = () => {
  const { id } = useParams()

  return <DefaultPageComponent
    hideFlexGrow={true}
    content={<><BreadcrumbComponent props={{
      style: {
        maxWidth: 1024,
        marginInline: 'auto',
        boxSizing: 'border-box'
      }
    }} />{id ? <TicketComponent id={id} /> : <></>}</>}
    style={{
      background: '#F1F0F0',
    }}
  />
}