import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IApiClientResult } from 'fwork-jsts-common/src/apiClient'
import { ApiResponseGetListData } from 'fwork-jsts-common/src/api'
import { IDefaultAction, IDefaultState } from 'fwork.react.apptemplate'
import { ISetting, ITheme } from 'rifa.lib.common.ts/models'
import axios from '../../apiClient/axios'
import { SettingsApiClient } from '../../apiClient/settings'

export interface ISettingsAction extends IDefaultAction {
  payload?: ISetting[],
}

export interface ISettingsState extends IDefaultState {
  payload?: ISetting[],
}

const initState: ISettingsState = {}

export const settingsStateLoadFromApi = createAsyncThunk(
  'settingsStateLoadFromApi',
  async (arg: {
    apiUrl?: string | undefined,
    onSuccess?: () => void,
    onError?: (msg?: string) => void
  }) => {
    const IapiResult: IApiClientResult<ApiResponseGetListData<ISetting>> = await new SettingsApiClient(axios, arg.apiUrl).get()
    if (IapiResult.success) {
      if (arg.onSuccess)
        arg.onSuccess()
    }
    else if (arg.onError)
      arg.onError(IapiResult.msg)
    return IapiResult
  }
)

export const settingsSlice = createSlice({
  name: 'settingsSlice',
  initialState: initState,
  reducers: {
    set: (state, action: PayloadAction<ISetting[] | undefined>) => {
      state.payload = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(settingsStateLoadFromApi.pending, (state) => {
      if (!state.options)
        state.options = {}
      state.options.loading = true
    })
    builder.addCase(settingsStateLoadFromApi.fulfilled, (state, response) => {
      if (state.options)
        state.options.loading = false
      state.payload = response.payload.data?.payload
    })
    builder.addCase(settingsStateLoadFromApi.rejected, (state) => {
      if (state.options)
        state.options.loading = false
    })
  }
})

export const { set: settingsStateSet } = settingsSlice.actions

export default settingsSlice.reducer