import React from "react"
import { useSelector } from "react-redux"
import { IPrizeDrawsState } from "../../../redux/reducers/prizeDraws.slice"
import { RootState } from "../../../redux/store"
import { DefaultPageComponent } from "../../components/defaultPage"
import { PrizeDrawsComponent } from "../../components/prizeDraws"

export const PrizeDrawsPage = () => {
  const prizedrawsState: IPrizeDrawsState = useSelector((state: RootState) => state.prizeDrawsState)

  return <DefaultPageComponent>
    <PrizeDrawsComponent data={prizedrawsState.payload} />
  </DefaultPageComponent>
}