import { Button, styled } from "@mui/material"
import { FormItemStyled, FormStyled, SelectImgComponent } from "fwork.react.apptemplate"
import { useSnackbar } from "notistack"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { IPrizeDraw } from "rifa.lib.common.ts/models"
import axios from '../../../apiClient/axios'
import { PrizeDrawsApiClient } from "../../../apiClient/prizeDraws"
import { IDotEnvProductionState } from "../../../redux/reducers/dotenvProduction.slice"
import { RootState } from "../../../redux/store"
import { DEFAULTGAP } from "../../globals"
import { HomeTypes } from "../../types"
import { HeaderComponent } from "../miscelaneous/header"
import { PrizeDrawRankingComponent } from "../prizeDrawRanking"
import { PrizeDrawTicketsComponent } from "../prizeDrawTickets"
import { PrizeDrawAdditionalContainerComponent } from "./additional"
import { PrizeDrawAdditionalInputsComponent } from "./additionalInputs"
import { PrizeDrawMainInputs } from "./mainInputs"

const ImgWrapper = styled(FormItemStyled)(({ theme }) => ({
  width: '100% !important',
  alignItems: 'center',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start'
  }
}))

export const PrizeDrawComponent = (props: {
  data: IPrizeDraw,
  onConfirm?: () => void,
  onCancel?: () => void,
  onDelete?: () => void,
}) => {
  const [data, setData] = useState<IPrizeDraw>(props.data)
  const { enqueueSnackbar } = useSnackbar()
  const [homeType, setHomeType] = useState(HomeTypes.prizeDraw)
  const dotEnvProductionState: IDotEnvProductionState = useSelector((state: RootState) => state.dotEnvProductionState)

  useEffect(() => {
    setData(props.data)
  }, [props.data])

  let confirming = false
  const confirm = async () => {
    if (confirming) {
      enqueueSnackbar('Operação em andamento. Aguarde...', { variant: 'success' })
      return
    }
    confirming = true

    try {
      if ((data.limitCount || 0) <= 0) {
        enqueueSnackbar('Quantidade de cotas menor que zero.', {
          variant: 'error'
        })
        return
      }

      const apiResult = await (data._id ? new PrizeDrawsApiClient(axios).put({
        data,
      }) : new PrizeDrawsApiClient(axios).post({
        data,
      }))

      if (apiResult.success) {
        if (apiResult.data)
          setData(apiResult.data)
        enqueueSnackbar('Alterações salvas com sucesso', { variant: 'success' })
        if (props.onConfirm)
          props.onConfirm()
      }
      else {
        enqueueSnackbar(apiResult.msg, { variant: 'error' })
      }
    }
    finally {
      confirming = false
    }
  }

  return <>
    {/* {JSON.stringify(data)} */}
    {homeType == HomeTypes.prizeDraw ? <>
      <HeaderComponent
        props={{
          style: {
            paddingInline: DEFAULTGAP
          }
        }}
        title={`Sorteio ${data?.title || ''}`}
        rightContent={props.data?._id ?
          <div>
            <Button onClick={() => setHomeType(HomeTypes.prizeDrawTickets)}>Movimentação</Button>
            <Button onClick={() => setHomeType(HomeTypes.prizeDrawRanking)}>Ranking</Button>
          </div> : <></>}

        onConfirmClick={confirm}
        onCancelClick={props.onCancel}
      />

      <FormStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}>
        <ImgWrapper>
          <SelectImgComponent
            id="prizeDrawImgPicker"
            alwaysShowButton={true}
            onSelectImg={(src: string) => {
              setData({
                ...data,
                imgUrl: src
              })
            }}
            imgProps={{
              src: data.imgUrl?.indexOf(';base64,') != -1 ? data.imgUrl?.toString() : `${dotEnvProductionState.payload?.APIURL}/img/${data.imgUrl?.toString()}`,
              style: {
                minWidth: 200,
                maxWidth: 570,
                width: '100%',
                height: 160,
                borderRadius: 8,
                objectFit: 'cover',
              }
            }} />
        </ImgWrapper>

        <PrizeDrawMainInputs
          data={data}
          onChange={(data: IPrizeDraw) => {
            setData(data)
          }}
        />

        <div style={{ width: '100%' }}></div>

        <PrizeDrawAdditionalInputsComponent
          data={data}
          onChange={(data: IPrizeDraw) => {
            setData(data)
          }}
        />

      </FormStyled>

      {data?._id ? <PrizeDrawAdditionalContainerComponent
        prizeDrawOffPricesProps={{
          offPrices: data.offPrices || [],
          onChange: (offPrice) => {
            if (data) {
              const dataTemp = { ...data }
              dataTemp.offPrices = offPrice
              setData({
                ...dataTemp
              })
            }
          },
          onDelete: (index) => {
            let dataTemp: IPrizeDraw = {
              ...data,
              offPrices: [...data.offPrices || []]
            }
            dataTemp?.offPrices?.splice(index, 1)
            setData({
              ...dataTemp
            })
          }
        }}
        prizeDrawAwardedNumbersProps={{
          awardedNumbers: data.awardedNumbers || [],
          onChange: (awardedNumbers) => {
            if (data) {
              const dataTemp = { ...data }
              dataTemp.awardedNumbers = awardedNumbers
              setData({
                ...dataTemp
              })
            }
          },
          onDelete: (index) => {
            let dataTmp: IPrizeDraw = {
              ...data,
              awardedNumbers: [...data.awardedNumbers || []]
            }
            dataTmp?.awardedNumbers?.splice(index, 1)
            setData({
              ...dataTmp
            })
          }
        }} /> : <></>}

    </> : homeType == HomeTypes.prizeDrawTickets ?
      <PrizeDrawTicketsComponent prizeDraw={data} onBackClick={() => setHomeType(HomeTypes.prizeDraw)} /> :
      <PrizeDrawRankingComponent prizeDrawId={data?._id} onCloseClick={() => setHomeType(HomeTypes.prizeDraw)} />}
  </>
}