import { Button, Pagination, styled } from "@mui/material"
import React, { CSSProperties, useState } from "react"

const GridNumberItemComponent = (props: {
  number: number,
  onClick?: (number: number) => void,
  selected?: boolean,
  disable?: boolean,
}) => {

  const style: CSSProperties = {
    background: props.disable ? 'silver' : props.selected ? 'black' : 'grey',
    borderRadius: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px 10px',
    color: 'white',
  }

  return props.disable ? <div style={style}>
    {props.number}
  </div> : <Button style={style}
    onClick={() => {
      if (props.onClick)
        props.onClick(props.number)
    }}>{props.number}</Button>
}

const GridNumbersStyled = styled('div')(({ theme }) => ({
  width: '100%',

  color: 'white',
  display: 'grid',
  gridTemplateColumns: 'auto auto auto auto',
  gap: 5,

  [theme.breakpoints.up('sm')]: {
    gridTemplateColumns: 'auto auto auto auto auto auto',
  },

  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: 'auto auto auto auto auto auto auto auto',
  },

  [theme.breakpoints.up('lg')]: {
    gridTemplateColumns: 'auto auto auto auto auto auto auto auto auto auto',
  }
}))

export const GridNumbersComponent = (props: {
  from?: number,
  to?: number,
  numbers?: number[],
  disableNumbers?: number[],
  initSelected: number[],
  rangeCount?: number,
  onChange?: (selected: number[]) => void,
  props?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
}) => {
  const [currPage, setCurrPage] = useState(1)

  const numbers = props.numbers ?? []
  const from = props.from ?? (props.numbers?.length ? 1 : 0)
  const to = (props.to ?? props.numbers?.length ?? 0)

  if (!props.numbers?.length && from && props.to)
    for (let i = from; i <= props.to; i++) {
      numbers.push(i)
    }

  const rangeCount = props.rangeCount ?? 100

  let pageCount = Math.trunc(to / rangeCount)
  if (to % rangeCount > 0)
    pageCount++

  const firstElement = (currPage * rangeCount) - rangeCount + 1
  const lastElement = (currPage * rangeCount)

  return <div className="GridNumbersComponent" {...props.props}>
    <GridNumbersStyled>
      {numbers.slice(firstElement - 1, lastElement).map(n => <GridNumberItemComponent
        key={n}
        selected={props.initSelected.indexOf(n) !== -1}
        number={n}
        disable={props.disableNumbers ? props.disableNumbers.indexOf(n) !== -1 : false}
        onClick={(number: number) => {
          if (props.initSelected.indexOf(number) !== -1)
            props.initSelected.splice(props.initSelected.indexOf(number), 1)
          else {
            props.initSelected.push(number)
          }
          if (props.onChange)
            props.onChange(props.initSelected)
        }} />
      )}
    </GridNumbersStyled>

    {pageCount > 1 ? <div style={{
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center'
    }}>
      <Pagination
        style={{
          marginTop: 10,
        }}
        count={pageCount}
        variant='outlined'
        shape='rounded'
        onChange={(event: React.ChangeEvent<unknown>, value: number) => {
          setCurrPage(value);
        }}
      />
    </div> : <></>}
  </div>
}