import { Button, DialogProps } from "@mui/material";
import React from "react";
import { DialogComponent } from ".";

export interface DialogConfirmProps extends DialogProps {
  title?: string,
  text?: string,
  onConfirm?: () => void,
  onCancel?: () => void,
}

export const ConfirmDialogComponent = (props: DialogConfirmProps) => {
  return <DialogComponent {...props}>
    <>
      {props.title ? <h3 style={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', width: '100%', margin: 0 }}>{props.title}</h3> : <></>}
      {props.text ? <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', width: '100%' }}>{props.text}</div> : <></>}
      {props.children}
      <div style={{ display: 'flex', flexDirection: 'row', gap: 10, flexWrap: 'wrap' }}>
        {props.onConfirm ? <Button style={{
          flexGrow: 1
        }} variant="contained" color="primary"
          onClick={props.onConfirm}>Confirmar</Button> : <></>}
        <Button style={{
          flexGrow: 1
        }} variant="contained" color="error"
          onClick={props.onCancel}>Fechar</Button>
      </div>
    </>
  </DialogComponent>
}