import React from "react"
import { DefaultPageComponent } from "../../components/defaultPage"
import { HomeComponent } from "../../components/home"

export const MainPage = () => {
  return <>
    <DefaultPageComponent
      showTutorial={true}
      hideFlexGrow={true}
      appBarOptions={{
        showSearch: true,
      }}
      content={<HomeComponent />}
    />
  </>
}