import { NumberUtils } from "fwork-jsts-common/src"
import React from "react"
import { FaFacebookF, FaInstagram, FaTwitter, FaWhatsapp } from "react-icons/fa"
import { useSelector } from "react-redux"
import { ISettingsState } from "../../../redux/reducers/settings.slice"
import { RootState } from "../../../redux/store"
import { defaultTheme } from "../../../common/globals"

export const SocialMediaComponent = (props: {
  props?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
}) => {
  const settingsState: ISettingsState = useSelector((state: RootState) => state.settingsState)

  return <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      gap: 10,
      alignItems: 'center',
    }}

    {...props.props}
  >
    <ul style={{
      listStyle: 'none',
      display: 'flex',
      gap: 10,
      alignItems: 'center',
      textDecoration: 'none',
      padding: 0,
      margin: 0
    }}>
      {settingsState.payload?.length ?
        <li>
          <a style={{ display: 'flex' }} href={`https://www.instagram.com/${settingsState.payload[0].instagram}`} target='_blank'> <FaInstagram color={settingsState?.payload?.[0]?.theme?.footerTextColor ?? defaultTheme.footerTextColor} />
          </a>
        </li> : <></>}
      {settingsState.payload?.length ?
        <li>
          <a style={{ display: 'flex' }} href={`https://wa.me/${NumberUtils.onlyNumbers(settingsState.payload[0].whatsapp)}`} target='_blank'> <FaWhatsapp color={settingsState?.payload?.[0]?.theme?.footerTextColor ?? defaultTheme.footerTextColor} />
          </a>
        </li> : <></>}
      {settingsState.payload?.length ?
        <li>
          <a style={{ display: 'flex' }} href={`https://twitter.com/${settingsState.payload[0].twitter}`} target='_blank'> <FaTwitter color={settingsState?.payload?.[0]?.theme?.footerTextColor ?? defaultTheme.footerTextColor} />
          </a>
        </li> : <></>}
      {settingsState.payload?.length ?
        <li>
          <a style={{ display: 'flex' }} href={`https://facebook.com/${settingsState.payload[0].facebook}`} target='_blank'> <FaFacebookF color={settingsState?.payload?.[0]?.theme?.footerTextColor ?? defaultTheme.footerTextColor} />
          </a>
        </li> : <></>}
    </ul>
  </div>
}