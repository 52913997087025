import { Box, Grid } from "@mui/material"
import { NumberUtils } from "fwork-jsts-common/src"
import React from "react"

export const PrizeDrawOffPricesComponent = (props: {
  _id?: string | any,
  price?: number,
  from?: number,

}) => {
  return <>
    <Box sx={{ flexGrow: 1, }}>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs sm md>
          <div style={{
            boxShadow: 'rgb(0 0 0 / 16%) 1px 1px 3px',
            background: 'white',
            borderRadius: 5,
            padding: 15,
          }}>{`A partir de ${props.from} números, o valor da cota é: R$ ${NumberUtils.getNumberStr(props.price || 0)}`}</div>
        </Grid>
      </Grid>
    </Box>
  </>
}