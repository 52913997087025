import { Form10x20Styled, FormItem10x20Styled } from "fwork.react.apptemplate";
import React from "react";
import { FaTrophy } from "react-icons/fa";
import { ITicket } from "rifa.lib.common.ts/models";
import { TicketWinnerComponent } from "../ticketWinner";
import { TicketWinnersStyled, TicketWinnersTitleStyled } from './index.styled';

export const TicketWinnersComponent = (
  props: {
    hidePrizeDrawTitle?: boolean,
    winners: ITicket[],
    props?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
  }
) => {
  return props.winners?.length ? <TicketWinnersStyled {...props.props}
    style={{
      ...props.props?.style
    }}>
    <TicketWinnersTitleStyled alignUpSm={props.winners.length >= 3 ? 'left' : 'center'}>
      Ganhadores <FaTrophy size={26} style={{
        marginLeft: '16px',
      }} />
    </TicketWinnersTitleStyled>
    <Form10x20Styled
      style={{
        color: '#404040',
        justifyContent: 'center',
        fontSize: '18px',
      }}>
      {props.winners.map((ticket, idx) => <FormItem10x20Styled key={idx}>
        <TicketWinnerComponent
          hidePrizeDrawTitle={props.hidePrizeDrawTitle}
          props={{
            style: {
              height: '100%',
              zIndex: 1,
            }
          }} ticket={ticket} />
      </FormItem10x20Styled>)}
    </Form10x20Styled>
  </TicketWinnersStyled> : <></>
}