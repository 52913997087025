import { Divider } from "@mui/material"
import { FlexGrowComponent, FormItemStyled, FormStyled } from "fwork.react.apptemplate"
import React from "react"
import { FaHome } from "react-icons/fa"
import { useSelector } from "react-redux"
import { Link, useLocation } from 'react-router-dom'
import { ITutorial } from "rifa.lib.common.ts/models"
import { RoutesNames, VERSION } from "../../../common/globals"
import { ISettingsState } from "../../../redux/reducers/settings.slice"
import { RootState } from "../../../redux/store"
import { SizedContainerStyled } from "../defaultPage"
import { SocialMediaComponent } from "../socialMedia"
import { TutorialComponent } from "./tutorial"

export const FooterComponent = (props: {
  showTutorial?: boolean
}) => {
  const settingsState: ISettingsState = useSelector((state: RootState) => state.settingsState)
  const location = useLocation()

  const showTutorial = props.showTutorial && (settingsState.payload?.length ? settingsState.payload[0].tutorials?.length : false)
  let tutorials: ITutorial[] | undefined
  if (settingsState.payload?.length)
    if (settingsState.payload[0].tutorials?.length)
      tutorials = settingsState.payload[0].tutorials

  return <>
    {showTutorial ? <SizedContainerStyled className="SizedContainerStyled" style={{
      background: '#cecece'
    }}>
      <TutorialComponent tutorials={tutorials} />
    </SizedContainerStyled> : <></>}

    <SizedContainerStyled className="SizedContainerStyled" style={{
      background: '#4A4A4A',
      color: '#A3A3A3',
      boxSizing: 'border-box',
      paddingBottom: 10
    }}>
      <FormStyled style={{
        width: '100%',
      }}>
        {location.pathname != RoutesNames.home ? <FormItemStyled style={{ maxWidth: 100 }}>
          <Link style={{
            textDecoration: 'none',
            color: '#a3a3a3',
            display: 'flex',
            alignItems: 'center',
            gap: 10
          }} to={RoutesNames.home}><FaHome />Home</Link>
        </FormItemStyled> : <></>}

        <FormItemStyled style={{ maxWidth: 200 }}>
          <Link style={{
            textDecoration: 'none',
            color: '#a3a3a3',
            whiteSpace: 'nowrap'
          }} to={RoutesNames.useThermes}>Termos de uso</Link>

          <Link style={{
            textDecoration: 'none',
            color: '#a3a3a3',
            whiteSpace: 'nowrap'
          }} to={RoutesNames.privacyPolicy}>Política de privacidade</Link>
        </FormItemStyled>

        <FlexGrowComponent />

        <SocialMediaComponent />

        <FormItemStyled style={{ width: '100%' }}>
          <Divider />
        </FormItemStyled>

        <FlexGrowComponent />

        <FormItemStyled style={{ display: 'inline-flex' }}>
          <div style={{ alignSelf: 'flex-end', marginLeft: 10, display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            {settingsState.payload?.length ? <img alt="" src={settingsState.payload[0].companyLogoUrl?.toString()} style={{
              width: 100,
              marginBottom: 10,
            }} /> : <></>}
            <span>{VERSION}</span>
            <span>Desenvolvido por SL7 Tecnologia</span>
          </div>
        </FormItemStyled>
      </FormStyled>
    </SizedContainerStyled>
  </>
}