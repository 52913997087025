import { Checkbox, Divider, FormControl, FormControlLabel, Radio, RadioGroup, TextField } from "@mui/material"
import { FormItemStyled } from "fwork.react.apptemplate"
import React from "react"
import { IPrizeDraw } from "rifa.lib.common.ts/models"
import { DEFAULTGAP } from "../../globals"

export const PrizeDrawAdditionalInputsComponent = (props: {
  data: IPrizeDraw,
  onChange?: (data: IPrizeDraw) => void
}) => {
  return (
    <>
      <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}
        style={{
          justifyContent: 'flex-end',
          width: 'auto'
        }}>
        <FormControlLabel
          label='Destacar Sorteio'
          control={
            <Checkbox
              checked={props.data?.highLight ?? false}
              onChange={(e) => {
                if (props.onChange) {
                  props.onChange({
                    ...props.data,
                    highLight: e.target.checked
                  })
                }
              }}
            />
          }
        />
      </FormItemStyled>

      <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}
        style={{
          justifyContent: 'flex-end',
          width: 'auto'
        }}>
        <FormControlLabel
          label='Permitir mudar os números do bilhete'
          control={
            <Checkbox
              checked={props.data?.allowRenewNumbers ?? false}
              onChange={(e) => {
                if (props.onChange) {
                  props.onChange({
                    ...props.data,
                    allowRenewNumbers: e.target.checked
                  })
                }
              }}
            />
          }
        />
      </FormItemStyled>
      <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}
        style={{
          justifyContent: 'flex-end',
          width: 'auto'
        }}>
        <FormControlLabel
          label='Exibir Ranking'
          control={
            <Checkbox
              checked={props.data?.showRanking ?? false}
              onChange={(e) => {
                if (props.onChange) {
                  props.onChange({
                    ...props.data,
                    showRanking: e.target.checked
                  })
                }
              }}
            />
          }
        />
      </FormItemStyled>

      <Divider style={{
        marginLeft: DEFAULTGAP,
        marginTop: DEFAULTGAP,
        width: `calc(100% - ${DEFAULTGAP}px)`
      }} />

      <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}
        style={{
          justifyContent: 'flex-end',
          width: 'auto'
        }}>
        <FormControlLabel
          label='Exibir botões de quantidades adicionais'
          control={
            <Checkbox
              checked={props.data?.showCountButtons ?? false}
              onChange={(e) => {
                if (props.onChange) {
                  props.onChange({
                    ...props.data,
                    showCountButtons: e.target.checked
                  })
                }
              }}
            />
          }
        />
      </FormItemStyled>

      <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}
        style={{
          justifyContent: 'flex-end',
          width: 'auto'
        }}>
        <FormControlLabel
          label='Exibir números já vendidos'
          control={
            <Checkbox
              checked={props.data?.showSoldNumbers ?? false}
              onChange={(e) => {
                if (props.onChange) {
                  props.onChange({
                    ...props.data,
                    showSoldNumbers: e.target.checked
                  })
                }
              }}
            />
          }
        />
      </FormItemStyled>

      <Divider style={{
        marginLeft: DEFAULTGAP,
        marginTop: DEFAULTGAP,
        width: `calc(100% - ${DEFAULTGAP}px)`
      }} />

      <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}
        style={{
          justifyContent: 'flex-end',
          width: 'auto'
        }}>
        <FormControlLabel
          label='Exibir os números selecionados'
          control={
            <Checkbox
              checked={props.data?.showSelectedNumbers ?? false}
              onChange={(e) => {
                if (props.onChange) {
                  props.onChange({
                    ...props.data,
                    allowChooseNumbers: e.target.checked ? props.data.allowChooseNumbers : false,
                    showNumbersOnlyPaidInSale: e.target.checked ? props.data.showNumbersOnlyPaidInSale : false,
                    showSelectedNumbers: e.target.checked
                  })
                }
              }}
            />
          }
        />
      </FormItemStyled>

      <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}
        style={{
          justifyContent: 'flex-end',
          width: 'auto'
        }}>
        <FormControl>
          <RadioGroup row>
            <FormControlLabel
              disabled={!props.data.showSelectedNumbers}
              label='Sempre'
              control={
                <Radio
                  checked={!props.data.showNumbersOnlyPaidInSale}
                  onChange={(e) => {
                    if (props.onChange) {
                      props.onChange({
                        ...props.data,
                        showNumbersOnlyPaidInSale: false
                      })
                    }
                  }}
                  value="a"
                  name="radio-buttons"
                  inputProps={{ 'aria-label': 'A' }}
                />
              }
            />
            <FormControlLabel
              disabled={!props.data.showSelectedNumbers}
              label='Apenas após o pagamento'
              control={
                <Radio
                  checked={props.data.showNumbersOnlyPaidInSale}
                  onChange={(e) => {
                    if (props.onChange) {
                      props.onChange({
                        ...props.data,
                        showNumbersOnlyPaidInSale: true,
                        allowChooseNumbers: false,
                      })
                    }
                  }}
                  value="a"
                  name="radio-buttons"
                  inputProps={{ 'aria-label': 'A' }}
                />
              }
            />
          </RadioGroup>
        </FormControl>
      </FormItemStyled>

      <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}
        style={{
          justifyContent: 'flex-end',
          width: 'auto'
        }}>
        <FormControlLabel
          label='Permitir escolha dos números'
          control={
            <Checkbox
              checked={props.data?.allowChooseNumbers ?? false}
              onChange={(e) => {
                if (props.onChange) {
                  props.onChange({
                    ...props.data,
                    allowChooseNumbers: e.target.checked,
                    showSelectedNumbers: e.target.checked || props.data.showSelectedNumbers,
                    showNumbersOnlyPaidInSale: e.target.checked ? false : props.data.showNumbersOnlyPaidInSale
                  })
                }
              }}
            />
          }
        />
      </FormItemStyled>

      {props.data._id ?
        <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}
          style={{ width: '100%' }}>
          <TextField
            label='Números sorteados (colocar os números separados por vírgula)'
            value={props.data?.drawnNumbers?.toString().replace('[', '').replace(']', '')}
            onChange={(e) => {
              if (props.onChange) {
                props.onChange({
                  ...props.data,
                  drawnNumbers: e.target.value.split(',').map(v => Number(v))
                })
              }
            }}
          />
        </FormItemStyled>
        : <></>
      }

      {props.data._id ?
        <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}
          style={{ justifyContent: 'flex-end', width: 'auto' }}>
          <FormControlLabel
            label='Sorteios encerrados'
            control={
              <Checkbox
                checked={props.data?.closed ?? false}
                onChange={(e) => {
                  if (props.onChange) {
                    props.onChange({
                      ...props.data,
                      closed: e.target.checked
                    })
                  }
                }}
              />
            }
          />
        </FormItemStyled>
        : <></>
      }
    </>
  )
}
