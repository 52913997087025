import { ApiClientUtils } from 'fwork-jsts-common/src/apiClient';
import { TableComponent } from 'fwork-react-mui-ext/src/table';
import { StyledListItemText, StyledTableCell } from 'fwork-react-mui-ext/src/table/common';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ICustomer } from 'rifa.lib.common.ts/models';
import { HeaderComponent } from '../../../admin/components/miscelaneous/header';
import axios from '../../../apiClient/axios';
import { IDotEnvProductionState } from '../../../redux/reducers/dotenvProduction.slice';
import { RootState } from '../../../redux/store';
import { DEFAULTGAP } from '../../globals';
import { CustomerRankingComponent } from './rankingCustomer';

interface IBestPosition {
  customers: ICustomer[],
  count: number,
}

export const PrizeDrawRankingComponent = (props: {
  prizeDrawId: any,
  onCloseClick?: () => void
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const dotEnvProductionState: IDotEnvProductionState = useSelector((state: RootState) => state.dotEnvProductionState)
  const [ranking, setRanking] = useState<ICustomer[] | undefined>()

  useEffect(() => {
    loadCustomerRanking()
  }, [])

  const getBestPositions = () => {
    const bestPositions: IBestPosition[] = []
    if (ranking?.length)
      for (const customer of ranking) {
        if (!customer.ticketsNumbersCount) continue
        // vai pra primeira posicao
        if (bestPositions.length == 0) {
          bestPositions.push({
            customers: [customer],
            count: customer.ticketsNumbersCount ?? 0
          })
        }
        // vai pra primeira se for igual ou pra segunda se for menor 
        else if (bestPositions.length == 1) {
          if (customer.ticketsNumbersCount == bestPositions[0]?.count)
            bestPositions[0]?.customers.push(customer)
          else if (customer.ticketsNumbersCount < bestPositions[0]?.count)
            bestPositions.push({
              customers: [customer],
              count: customer.ticketsNumbersCount ?? 0
            })
        }
        // vai pra segunda se for igual ou pra terceira se for menor
        else if (bestPositions.length == 2) {
          if (customer.ticketsNumbersCount == bestPositions[1]?.count)
            bestPositions[1]?.customers.push(customer)
          else if (customer.ticketsNumbersCount < bestPositions[1]?.count)
            bestPositions.push({
              customers: [customer],
              count: customer.ticketsNumbersCount ?? 0
            })
        }
        // vai pra terceira se for igual, se for menor sai do loop
        else if (bestPositions.length == 3) {
          if (customer.ticketsNumbersCount == bestPositions[2]?.count)
            bestPositions[2]?.customers.push(customer)
          else if (customer.ticketsNumbersCount < bestPositions[2]?.count)
            break
        }
      }
    return bestPositions
  }

  const bestPositions: IBestPosition[] = getBestPositions()


  const loadCustomerRanking = async () => {
    try {
      const response = await axios.get(`${dotEnvProductionState.payload?.APIURL}/prizedraws/${props.prizeDrawId}/customer-ranking-by-quotecount`)
      if (response.status == 200) {
        setRanking(response.data.payload)
      }
    }
    catch (error) {
      enqueueSnackbar(ApiClientUtils.getErrorMessage(error), { variant: 'error' })
    }
  }

  return ranking?.length ? <div style={{
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    height: '100%',
  }}>
    <HeaderComponent
      title='Ranking de Compradores Com Maiores Chances de Ganhar 🚀'
      onRefreshClick={loadCustomerRanking}
      onCancelClick={props.onCloseClick}
    />

    <TableComponent
      tableProps={{
        style: {
          background: 'white',
          zIndex: 1
        }
      }}
      bodyList={ranking ?? []}
      bodyRowBuilder={(customer: ICustomer) => <>
        <StyledTableCell style={{ display: 'flex', alignItems: 'center', gap: DEFAULTGAP }}>
          <CustomerRankingComponent customer={customer} />
          <StyledListItemText
            primary={customer.name}
            secondary={`Está concorrendo com ${customer.ticketsNumbersCount} cotas`}
          />
          {bestPositions?.[0]?.count == customer.ticketsNumbersCount ? <label style={{ fontSize: 30 }}>🏅</label> : <></>}
          {bestPositions?.[1]?.count == customer.ticketsNumbersCount ? <label style={{ fontSize: 30 }}>🥈</label> : <></>}
          {bestPositions?.[2]?.count == customer.ticketsNumbersCount ? <label style={{ fontSize: 30 }}>🥉</label> : <></>}
        </StyledTableCell>
      </>}
    />
  </div> : <></>
}