import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { IUser } from "rifa.lib.common.ts/models"
import axios from '../../../apiClient/axios'
import { UsersApiClient } from "../../../apiClient/users"
import { DefaultPageComponent } from "../../components/defaultPage"
import { UserComponent } from "../../components/user"

export const AdminUserPage = () => {
  const { id } = useParams()
  const [data, setData] = useState<IUser>()
  const navigator = useNavigate()
  
  const getData = async () => {
    let response = await new UsersApiClient(axios).get({
      where: {
        _id: id
      }
    })

    if (response.success && response.data?.payload?.length) {
      setData(response.data?.payload![0])
    }
  }

  useEffect(() => {
    if (id && id != 'new')
      getData()
  }, [])

  return <DefaultPageComponent>
    <UserComponent
      onConfirm={() => undefined}
      onCancel={() => navigator(-1)}
      onDelete={() => undefined}
      data={data ?? {} as IUser} />
  </DefaultPageComponent>
}