import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { WebUtils } from 'fwork-jsts-common/src'
import { IDefaultAction, IDefaultState } from 'fwork.react.apptemplate'
import axios from '../../apiClient/axios'
import { prizeDrawsStateLoadFromApi } from './prizeDraws.slice'
import { settingsStateLoadFromApi } from './settings.slice'

export const loadProductionEnv = async () => {
  let r: IDotEnvProduction

  if (process.env.NODE_ENV == 'production') {
    const envurl = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/.env'
    const response = await axios.get(envurl)

    let envLines = response.data.split('\r\n')
    let envItems: any = {}
    for (let i = 0; i < envLines.length; i++) {
      if (envLines[i].trim()[0] === '#') continue
      const keyValuePair = envLines[i].split('=')
      envItems[keyValuePair[0]] = keyValuePair[1]
    }

    r = envItems
  }
  else {
    r = {
      APIURL: process.env['APIURL'] || '',
    }
  }

  WebUtils.setCookie('@dotEnvProduction', JSON.stringify(r), 1)

  return r
}

export interface IDotEnvProduction {
  APIURL: string,
}

export interface IDotEnvProductionAction extends IDefaultAction {
  payload?: IDotEnvProduction,
}

export interface IDotEnvProductionState extends IDefaultState {
  payload?: IDotEnvProduction,
}

const initState: IDotEnvProductionState = {}

export const dotEnvProductionStateLoadFromApi = createAsyncThunk(
  'dotEnvProductionStateLoadFromApi',
  async (params, { dispatch }) => {
    let r: IDotEnvProduction = await loadProductionEnv()

    dispatch<any>(settingsStateLoadFromApi({
      apiUrl: `${r.APIURL}/settings`
    }))

    dispatch<any>(prizeDrawsStateLoadFromApi({
      apiUrl: `${r.APIURL}/prizedraws`,
      exclude: 'tickets'
    }))

    return r
  }
)

export const dotEnvProductionSlice = createSlice({
  name: 'dotEnvProductionSlice',
  initialState: initState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder.addCase(dotEnvProductionStateLoadFromApi.pending, (state) => {
      if (!state.options)
        state.options = {}
      state.options.loading = true
    })
    builder.addCase(dotEnvProductionStateLoadFromApi.fulfilled, (state, response) => {
      if (state.options)
        state.options.loading = false
      state.payload = response.payload
    })
    builder.addCase(dotEnvProductionStateLoadFromApi.rejected, (state) => {
      if (state.options)
        state.options.loading = false
    })
  }
})

export default dotEnvProductionSlice.reducer