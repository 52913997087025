import { ImageOrInitLettersComponent } from "fwork.react.apptemplate/src/components/image"
import React from "react"
import { useSelector } from "react-redux"
import { ICustomer } from "rifa.lib.common.ts/models"
import { IDotEnvProductionState } from "../../../redux/reducers/dotenvProduction.slice"
import { RootState } from "../../../redux/store"

interface CustomerComponentProps {
  customer: ICustomer
}

export const CustomerRankingComponent = (props: CustomerComponentProps) => {
  const dotEnvProductionState: IDotEnvProductionState = useSelector((state: RootState) => state.dotEnvProductionState)

  return (
    <ImageOrInitLettersComponent
      src={`${dotEnvProductionState.payload?.APIURL}/img/${props.customer.imgProfileUrl}`}
      initLettersText={props.customer.name}
      onlyFirstAndLastWord={true}
      style={{
        width: 45,
        height: 45,
        objectFit: 'cover',
        borderRadius: '100%',
        color: 'white',
        fontSize: 20
      }}
    />
  )
}
