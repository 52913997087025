import React from 'react'
import { Dialog, DialogContent, DialogProps } from "@mui/material"
import { styled } from '@mui/material'

export const DialogWrapperStyle = styled(Dialog)(({theme}) => ({
  padding: 0
}))

export const DialogContentWrapperStyled = styled(DialogContent)(({theme}) => ({
  padding: 20,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 10,
  [theme.breakpoints.up('sm')]: {
    gap: 20
  }
}))

export const DialogComponent = (props: DialogProps) => {
  return <DialogWrapperStyle className='DialogComponent' {...props}>
    <DialogContentWrapperStyled>
      {props.children}
    </DialogContentWrapperStyled>
  </DialogWrapperStyle>
}