import { createSlice } from '@reduxjs/toolkit'
import { ProgressOptions } from '../../models'
import { IDefaultAction, IDefaultState } from '../types'

export interface IProgressAction extends IDefaultAction {
  payload: ProgressOptions
}

export interface IProgressState extends IDefaultState {
  payload: ProgressOptions
}

const initState: IProgressState = {
  payload: {},
}

export const progressSlice = createSlice({
  name: 'progress',
  initialState: initState,
  reducers: {
    set: (state, action: IProgressAction) => {
      state.payload = action.payload      
    },
  },
  extraReducers: (builder) => {
    
  }
})

export const { set: progressStateSet } = progressSlice.actions

export default progressSlice.reducer