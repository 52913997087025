import React from 'react'
import { ITutorial } from 'rifa.lib.common.ts/models'
import { DEFAULTGAP } from "../../globals"
import { HeaderComponent } from '../miscelaneous/header'
import { TutorialComponent } from './tutorial'


export const TutoriaisComponent = (props: {
  tutorials: ITutorial[],
  onChange?: (tutorials: ITutorial[]) => void,
  onDelete?: (idx: number) => void
}) => {
  let tutorials: ITutorial[] = [...props.tutorials]

  return <div style={{
    display: 'flex',
    flexDirection: 'column',
    padding: DEFAULTGAP,
    gap: DEFAULTGAP,
    background: '#F9F9F9'
  }}>
    <HeaderComponent
      props={{
        style: {
          paddingInline: DEFAULTGAP
        }
      }}
      title={'Tutoriais'}
      onAddClick={() => {
        const tutorialsTmp = [...tutorials]
        tutorialsTmp.push({} as ITutorial)
        if (props.onChange)
          props.onChange(tutorialsTmp)
      }} />
      
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      gap: DEFAULTGAP
    }}>
      {tutorials?.map((tutorial, idx) => <TutorialComponent
        onTrashClick={() => {
          if (props.onDelete)
            props.onDelete(idx)
        }}
        key={idx} tutorial={tutorial} onChange={(pTutorial) => {
          const tutorialsTmp = [...tutorials]
          tutorialsTmp[idx] = pTutorial
          if (props.onChange)
            props.onChange(tutorialsTmp)
        }} />)}
    </div>
  </div>
}