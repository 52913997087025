import React from "react"
import { useLocation } from "react-router-dom"
import { BreadcrumbComponent } from "../../../common/components/breadcrumb"
import { DefaultPageComponent } from "../../components/defaultPage"
import { UserTicketsComponent } from "../../components/userTickets"

export const UserTicketsPage = () => {
  const location = useLocation()
  const filter: string = location.state?.filter

  return <DefaultPageComponent
    content={<>
      <BreadcrumbComponent/>
      <UserTicketsComponent where={filter} />
    </>}
  />
}