import { Form10x20Styled, FormItem10x20Styled } from "fwork.react.apptemplate"
import React from "react"
import { ITicket } from "rifa.lib.common.ts/models"
import { HeaderComponent } from "../../../admin/components/miscelaneous/header"
import { TicketItemCardComponent, TicketItemType } from "../ticketSmall"

export const TicketsComponent = (props: {
  tickets: ITicket[]
  itemType: TicketItemType,
  title: string,
  gap?: number,
  props?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
  onRefreshClick?: () => void,
  onCloseClick?: () => void,
}) => {
  return <Form10x20Styled className="TicketsComponent" {...props.props}>
    <FormItem10x20Styled style={{
      width: '100%',
      marginTop: 0
    }}>
      <HeaderComponent
        title={props.title}
        onRefreshClick={props.onRefreshClick}
        onCancelClick={props.onCloseClick}
        props={{
          style: {
            paddingInline: props.gap
          }
        }}
      />
    </FormItem10x20Styled>
    {props.tickets.map((t, idx) => <FormItem10x20Styled gap={props.gap} key={idx}>
      <TicketItemCardComponent showPaymentStatus={true} type={props.itemType} ticket={t} props={{
        style: {
          height: '100%'
        }
      }} />
    </FormItem10x20Styled>)}
  </Form10x20Styled>
}