import { IUser, UserTypes } from 'rifa.lib.common.ts/models'
import { RequiredAuthWrapperComponent } from 'fwork.react.apptemplate'
import { SnackbarProvider } from 'notistack'
import React, { useEffect, useState } from 'react'
import { Provider, useSelector } from 'react-redux'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { DashboardPage } from '../admin/pages/dashboard'
import { PrizeDrawsPage as AdminPrizeDrawsPage } from '../admin/pages/prizeDraws'
import { PrizeDrawsMovementPage as AdminPrizeDrawsMovementPage } from '../admin/pages/prizeDrawsMovement'
import { SettingsPage as AdminSettingsPage } from '../admin/pages/settings'
import { UsersPage as AdminUsersPage } from '../admin/pages/users'
import { MetaPixelComponent } from './components/metaPixel'
import { LoadingBarLinearComponent } from './components/progressBar'
import { RoutesNames, onLoginPayloadOverride } from './globals'
import { IDotEnvProductionState } from '../redux/reducers/dotenvProduction.slice'
import { RootState, store } from '../redux/store'
import { CookiePolicyComponent } from '../site/components/cookiePolicy'
import { MainPage as MainSitePage } from '../site/pages/home'
import { LoginPage } from '../site/pages/login'
import { PrivacyPolicyPage } from '../site/pages/privacyPolicy'
import { PrizeDrawPage } from '../site/pages/prizeDraw'
import { TicketPage } from '../site/pages/ticket'
import { UseThermsPage } from '../site/pages/useThermes'
import { UserTicketsPage } from '../site/pages/userTickets'
import { useTitle } from './hooks/usetitle'
import { AdminUserPage } from '../admin/pages/user'
import { AdminPrizeDrawPage } from '../admin/pages/prizeDraw'

const RequiredAuthWrapperComponentExtended = (props: {
  children?: React.ReactNode,
  allowedUserTypes?: UserTypes[],
}) => {
  const dotEnvProductionState: IDotEnvProductionState = useSelector((state: RootState) => state.dotEnvProductionState)

  const { allowedUserTypes } = props
  const defaultAllowedUser = [UserTypes.admin]
  const finalAllowedTypes = allowedUserTypes || defaultAllowedUser

  return <RequiredAuthWrapperComponent<IUser>
    loginOptions={{
      apiUrl: `${dotEnvProductionState.payload?.APIURL}/auth`,
      onPayloadOverride: onLoginPayloadOverride,
      checkAuthCallBack:(authentication) => {
        let userHasRequiredUserTypes = finalAllowedTypes.some(userType => authentication?.user.userTypes?.includes(userType))
        return authentication && userHasRequiredUserTypes
      }
    }}
  >
    {props.children}
  </RequiredAuthWrapperComponent>
}

const router = createBrowserRouter([
  {
    path: RoutesNames.home,
    element: <MainSitePage />
  },
  {
    path: RoutesNames.friendlyUrl,
    element: <PrizeDrawPage />
  },
  {
    path: RoutesNames.friendlyUrlAffiliate,
    element: <PrizeDrawPage />
  },
  {
    path: RoutesNames.prizeDraw,
    element: <PrizeDrawPage />
  },
  {
    path: RoutesNames.prizeDrawAffiliate,
    element: <PrizeDrawPage />
  },
  {
    path: RoutesNames.ticket,
    element: <TicketPage />
  },
  {
    path: RoutesNames.userTickets,
    element: <UserTicketsPage />
  },
  {
    path: RoutesNames.useThermes,
    element: <UseThermsPage />
  },
  {
    path: RoutesNames.privacyPolicy,
    element: <PrivacyPolicyPage />
  },
  {
    path: RoutesNames.login,
    element: <LoginPage />
  },

  // adm
  {
    path: RoutesNames.adminDashboard,
    element: <RequiredAuthWrapperComponentExtended>
      <DashboardPage />
    </RequiredAuthWrapperComponentExtended>,
  },
  {
    path: RoutesNames.adminSettings,
    element: <RequiredAuthWrapperComponentExtended>
      <AdminSettingsPage />
    </RequiredAuthWrapperComponentExtended>,
  },
  {
    path: RoutesNames.adminUsers,
    element: <RequiredAuthWrapperComponentExtended>
      <AdminUsersPage />
    </RequiredAuthWrapperComponentExtended>,
  },
  {
    path: RoutesNames.adminUser,
    element: <RequiredAuthWrapperComponentExtended>
      <AdminUserPage />
    </RequiredAuthWrapperComponentExtended>,
  },
  {
    path: RoutesNames.adminPrizedraws,
    element: <RequiredAuthWrapperComponentExtended>
      <AdminPrizeDrawsPage />
    </RequiredAuthWrapperComponentExtended>,
  },
  {
    path: RoutesNames.adminPrizedraw,
    element: <RequiredAuthWrapperComponentExtended>
      <AdminPrizeDrawPage />
    </RequiredAuthWrapperComponentExtended>,
  },
  {
    path: RoutesNames.adminPrizedrawsMovement,
    element: <RequiredAuthWrapperComponentExtended allowedUserTypes={[UserTypes.admin, UserTypes.seller, UserTypes.affiliate]}>
      <AdminPrizeDrawsMovementPage />
    </RequiredAuthWrapperComponentExtended>,
  }
])

const Overlay = () => {
  const [showingAlert, setState] = useState<{ showingAlert: boolean }>()

  useEffect(() => {
    handleClickShowAlert()
  }, [])

  const handleClickShowAlert = () => {
    setState({
      showingAlert: true
    })

    setTimeout(() => {
      setState({
        showingAlert: false
      })
    }, 5000)
  }

  return <div style={{
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: 'red',
    opacity: .5,
    zIndex: 1000000000,
    transition: 'all 2s'
  }}></div>
}

// TODO-joab colocar uma verificacao para nao iniciar a aplicacao caso nao tenha um provedor de pagamentos no env
// isso duplica as configuracoes quando tenta salvar alteracoes nela
export const App = () => {
  useTitle('');
  return <SnackbarProvider maxSnack={3} preventDuplicate={true} autoHideDuration={2000}
    anchorOrigin={{
      horizontal: 'right',
      vertical: 'top'
    }}>
    <Provider store={store}>
      {/* <Overlay /> */}
      <MetaPixelComponent />
      <CookiePolicyComponent />
      <LoadingBarLinearComponent />
      <RouterProvider router={router} />
    </Provider>
  </SnackbarProvider>
}