import React from 'react'
import { ITicketPayment, PaymentStatus, PaymentStatusDescriptions, TicketPaymentUtils } from 'rifa.lib.common.ts/models'
import DownTimerComponent from '../../../common/components/downTimer'

export const TicketStatusComponent = (props: {
  transaction: ITicketPayment
}) => {
  const remainingQrExpirationSecs = TicketPaymentUtils.getRemainingExpirationSecs({
    transaction: props.transaction
  })
  const showQrExpired = TicketPaymentUtils.getQrExpired({
    transaction: props.transaction
  })
  const showStatus = props.transaction.status !== undefined && !showQrExpired
  const showQr = props.transaction.status === PaymentStatus.waitingPayment && remainingQrExpirationSecs > 0
  const showQrTimer = showQr

  return <div style={{ display: 'flex', alignItems: 'center' }}>
    {showStatus ?
      <div style={{
        display: 'flex',
        padding: 5,
        borderRadius: 5,
        background: props.transaction.status === PaymentStatus.canceled ? 'red' :
          props.transaction.status === PaymentStatus.paid ? 'green' : 'blue',
        color: 'white',
        textTransform: 'uppercase',
        gap: 5
      }}>
        {PaymentStatusDescriptions.filter(s => s.value === props.transaction.status)[0].description}

        {/* TIMER QUE EXIBE O TEMPO RESTANTE PARA PAGAR O QRCODE */}
        {showQrTimer ? <DownTimerComponent style={{ marginLeft: 5 }} interval={remainingQrExpirationSecs} /> : <></>}
      </div> : <></>}

    {showQrExpired ? <div style={{ color: 'red'}}>Tempo para pagamento expirado</div> : <></>}
  </div>
}