import { IPrizeDrawOffPrice } from "rifa.lib.common.ts/models"
import moment from "moment"
import React from "react"
import { DEFAULTGAP } from "../../globals"
import { HeaderComponent } from "../miscelaneous/header"
import { PrizeDrawOffPriceComponent } from "../prizeDrawOffPrice"

export const PrizeDrawOffPricesComponent = (props: {
  offPrices: IPrizeDrawOffPrice[],
  onChange?: (offPrices: IPrizeDrawOffPrice[]) => void,
  onDelete?: (idx: number) => void
}) => {
  let offPrices: IPrizeDrawOffPrice[] = [...props.offPrices]

  return <div style={{
    display: 'flex',
    flexDirection: 'column',
    padding: DEFAULTGAP,
    gap: DEFAULTGAP,
    background: '#F9F9F9'
  }}>
    <HeaderComponent
      props={{
        style: {
          paddingInline: DEFAULTGAP,
        }
      }}
      title={'Promoções'}
      onAddClick={() => {
        const offPricesTemp = [...offPrices]
        offPricesTemp.unshift({
          initDateTime: moment().format(),
          endDatetime: moment().add(1, 'day').format(),
        } as IPrizeDrawOffPrice)
        if (props.onChange)
          props.onChange(offPricesTemp)
      }} />

    {offPrices?.map((offPrice, idx) => <PrizeDrawOffPriceComponent
      onTrashClick={() => {
        if (props.onDelete)
          props.onDelete(idx)
      }}
      key={idx} offPrice={offPrice} onChange={(pOffPrice) => {
        const offPricesTemp = [...offPrices]
        offPricesTemp[idx] = pOffPrice
        if (props.onChange)
          props.onChange(offPricesTemp)
      }} />)}
  </div>
}