import { CSSProperties } from 'react'

export const stylesModule = (height: string | number | undefined) => {
  const root: CSSProperties = {
    minHeight: height
  }

  const toolBar: CSSProperties = {
    minHeight: height,
    padding: '0px 10px'
  }

  const iconButton: CSSProperties = {
    margin: 0
  }

  const rightContent: CSSProperties = {
    margin: '0 10px'
  }

  return {
    root, toolBar, iconButton, rightContent,
  }
}