import React from "react"
import { IDotEnvProductionState } from "../../../redux/reducers/dotenvProduction.slice"
import { useSelector } from "react-redux"
import { RootState } from "../../../redux/store"

export const PrizeDrawBackGroundComponent = (props: {
  imgUrl: string
}) => {
  const dotEnvProductionState: IDotEnvProductionState = useSelector((state: RootState) => state.dotEnvProductionState)

  return <div className="PrizeDrawComponent-background" style={{
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundImage: `url(${dotEnvProductionState.payload?.APIURL}/img/${props.imgUrl})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    opacity: .1,
  }}></div>
}