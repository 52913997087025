import moment from 'moment'

export interface ITutorial {
  _id?: any,
  title: string,
  text: string
}

export interface IStyle {
  color: string
  textColor: string
}

export enum BannerObjectFitTypes {
  cover = 0,
  contain = 1,
}

export interface IBanner {
  _id?: any,
  title?: string,
  url: string,
  link?: string,
  objectFit: BannerObjectFitTypes
}

export interface ISetting {
  _id?: any,
  companyLogoUrl?: string,
  cookiePolicy?: string,
  privacyPolicy?: string,
  termsOfUse?: string,
  instagram?: string,
  facebook?: string,
  whatsapp?: string,
  twitter?: string,
  pixEntranceFee?: number,
  pixOutFee?: number,
  sl7PayApiKey?: string,

  tutorials?: ITutorial[],
  banners?: IBanner[],
}

export interface IUserAfilliateData {
  prizeDrawId: any,
  code: string,
}

export enum UserTypes {
  admin = 0,
  seller = 1,
  affiliate = 2,
}

export interface IUser {
  _id?: any,
  name: string,
  login: string,
  pwd: string,
  imgProfileUrl?: string,
  // userType: UserTypes,
  userTypes: UserTypes[],
  style?: IStyle,

  tickets?: ITicket[],
  prizedraws?: IPrizeDraw[],
  affiliateData?: IUserAfilliateData[],

  // NAO VAI PRA O BANCO
  oldPwd?: string,

  affiliateCode?: string,
  affiliateComissionValue?: number
  affiliateComissions?: IAffiliateCommission[],
}

export interface IPrizeDrawAwardedNumber {
  _id?: any,
  number: number,
  awardValue: number,
  validFromDateUnix: number,
}

export interface IPrizeDrawOffPrice {
  _id?: any,
  price: number,
  from: number,
  initDateTime?: string,
  endDatetime?: string,
}

export interface IPrizeDraw {
  _id?: any,
  title: string,
  description: string,
  imgUrl?: string,
  friendlyUrl?: string,
  datetime?: string,
  price: number,
  limitCount: number,
  limitDateTime?: string,
  closed?: boolean,
  drawnNumbers?: number[],
  ticketsCount?: number,
  minCount?: number,
  maxCount?: number,
  highLight?: boolean, // DESTACAR RIFA
  allowRenewNumbers?: boolean, // PERMITIR MUDAR OS NUMEROS DO BILHETE
  showCountButtons?: boolean, // EXIBIR BOTOES DE QTDS ADICIONAIS
  showSoldNumbers?: boolean, // EXIBIR NUMEROS JA VENDIDOS
  showSelectedNumbers?: boolean, // EXIBIR NUMEROS SELECIONADOS
  showNumbersOnlyPaidInSale?: boolean, // EXIBIR NUMEROS SELECIONADOS APENAS APOS O PAGAMENTO; DEPENDE DE showSelectedNumbers = true
  allowChooseNumbers?: boolean, // PERMITIR ESCOLHA DOS NUMEROS; DEPENDE DE showSelectedNumbers = true e showNumbersOnlyPaidInSale = false
  rules?: string,

  offPrices?: IPrizeDrawOffPrice[],
  awardedNumbers?: IPrizeDrawAwardedNumber[],

  userId: any,
  user?: IUser,

  tickets?: ITicket[]

  // NAO VAI PARA O BANCO
  paydSoldNumbersCount?: number,

}

export interface ICustomer {
  _id?: any,
  name: string,
  phoneNumber: string,
  cpf: string,
  email?: string,
  imgProfileUrl?: string,
  ticketsNumbersCount?: number,
  note?: string,
  style?: IStyle

  tickets?: ITicket[],
}

export enum PaymentTypes {
  generic = 0,
  pix = 1,
}

export enum PaymentStatus {
  undefined = -1,
  waitingConfirmation = 0,
  waitingPayment = 1,
  paid = 2,
  canceled = 9,
}

export const PaymentStatusDescriptions = [
  {
    description: 'Aguardando confirmação',
    value: 0,
  },
  {
    description: 'Aguardando pagamento',
    value: 1,
  },
  {
    description: 'Pago',
    value: 2,
  },
  {
    description: 'Cancelado',
    value: 9,
  },
]

export interface IPaymentWebHook {
  url?: string,
  token?: string,
}

export interface ITicketPayment {
  _id?: any,
  type: PaymentTypes,
  status: PaymentStatus,
  description?: string,
  value: number,

  // ESSE DADO VEM DA SL7PAY E ESTA EM UTC
  expirationDate?: string,
  webhook?: IPaymentWebHook,

  sl7PayId?: string,
  qrUrl?: string,
  qrImg?: string,
  pixEntranceFee?: number,
  pixOutFee?: number,
  note?: string,
}

export class TicketPaymentUtils {
  static getQrExpiration(args: {
    transaction: ITicketPayment
  }) {
    const qrExpiration = args.transaction.expirationDate ? moment(args.transaction.expirationDate.toString()) : null
    return qrExpiration
  }

  static getRemainingExpirationSecs(args: {
    transaction: ITicketPayment
  }) {
    const remainingQrExpirationSecs = args.transaction.status === PaymentStatus.waitingPayment ?
      moment.duration(this.getQrExpiration({
        transaction: args.transaction
      })?.diff(new Date())).asSeconds() : 0

    return remainingQrExpirationSecs
  }

  static getQrExpired(args: {
    transaction: ITicketPayment
  }) {
    const qrExpired = args.transaction.status === PaymentStatus.waitingPayment && this.getRemainingExpirationSecs({
      transaction: args.transaction
    }) <= 0

    return qrExpired
  }
}

export interface ITicketAwardInfo {
  number: number,
  awardValue: number,
}

export interface ITicket {
  _id?: any,
  description?: string,
  numbers: number[],
  unitValue: number,
  dateTime: string,
  winner?: boolean,
  canceled?: boolean,
  value: number,
  offPrice?: IPrizeDrawOffPrice,
  note?: string,
  renewNumbersToken?: string,

  prizeDrawId: any,
  prizeDraw?: IPrizeDraw,
  customerId: any,
  customer?: ICustomer,
  userId: any,
  user?: IUser,

  payments?: ITicketPayment[],
  awardedNumbers?: ITicketAwardInfo[],

  affiliateCode?: string, // nao vai para o banco
  affiliateId?: any,
  affiliateCommissions?: IAffiliateCommission[]
}

export interface ITransactionTag {
  checkedTicketCanceled: boolean
}

export interface IFriendlyUrl {
  _id?: any,
  prizeDrawId?: any,
  affiliateId?: any,
  url: string
}

export interface IAffiliateCommission {
  _id?: any,
  value: number,
  percentage: number,

  affiliateId: any,
  affiliate?: IUser,

  ticketId: any,
  ticket?: ITicket,
}