import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import * as React from 'react';
import { IBanner, ISetting, ITutorial } from 'rifa.lib.common.ts/models';
import { BannersComponent } from './banners';
import { SettingsFinanceComponent } from './finance';
import { TutoriaisComponent } from './tutorials';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function tabProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface tutorialsProps {
  tutorials: ITutorial[]
  onChange?: (tutorials: ITutorial[]) => void
  onDelete?: (idx: number) => void
}

interface bannersProps {
  banners: IBanner[]
  onChange?: (tutorials: IBanner[]) => void
  onDelete?: (idx: number) => void
}

interface financeProps {
  setting: ISetting,
  onChange?: (setting: ISetting) => void
}

export const SettingsAdditionalContainerComponent = (props: {
  tutorialsProps: tutorialsProps,
  bannersProps: bannersProps,
  financeProps: financeProps
}) => {
  const [currTab, setCurrTab] = React.useState(0);

  const onTabChange = (event: React.SyntheticEvent, newTabIndex: number) => {
    setCurrTab(newTabIndex);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={currTab} onChange={onTabChange}>
          <Tab label="Tutoriais" {...tabProps(0)} />
          <Tab label="Banners" {...tabProps(1)} />
          <Tab label="Financeiro" {...tabProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={currTab} index={0}>
        <TutoriaisComponent {...props.tutorialsProps} />
      </TabPanel>
      <TabPanel value={currTab} index={1}>
        <BannersComponent {...props.bannersProps} />
      </TabPanel>
      <TabPanel value={currTab} index={2}>
        <SettingsFinanceComponent {...props.financeProps} />
      </TabPanel>
    </Box>
  );
}