import React from "react"
import { useSelector } from "react-redux"
import { BreadcrumbComponent } from "../../../common/components/breadcrumb"
import { ISettingsState } from "../../../redux/reducers/settings.slice"
import { RootState } from "../../../redux/store"
import { DefaultPageComponent } from "../../components/defaultPage"
import { HtmlRawViewComponent } from "../../components/htmlRawView"

export const PrivacyPolicyPage = () => {
  const settingsState: ISettingsState = useSelector((state: RootState) => state.settingsState)
  const t = settingsState.payload ? settingsState.payload[0]?.privacyPolicy : null

  return <DefaultPageComponent
    content={<><BreadcrumbComponent/><HtmlRawViewComponent content={t || ''} /></>}
  />
}