import React from "react"
import { useSelector } from "react-redux"
import { ISetting } from "rifa.lib.common.ts/models"
import { ISettingsState } from "../../../redux/reducers/settings.slice"
import { RootState } from "../../../redux/store"
import { DefaultPageComponent } from "../../components/defaultPage"
import { SettingsComponent } from "../../components/settings/index"

export const SettingsPage = () => {
  const settingsState: ISettingsState = useSelector((state: RootState) => state.settingsState)
  const setting: ISetting = settingsState.payload?.length ? settingsState.payload[0] : {}  
  
  return <DefaultPageComponent>
    <SettingsComponent data={setting}/>
  </DefaultPageComponent>
}